import React from 'react';
import { Form } from '@ant-design/compatible';
import { Checkbox } from 'antd';

function GenerateKeywords({ getFieldDecorator }) {
  return (
    <Form.Item>
      {getFieldDecorator('keywords_as_seed', {
        initialValue: false,
      })(
        <Checkbox data-test="keywords_as_seed">
          Generate More Keywords
        </Checkbox>,
      )}
    </Form.Item>
  );
}

export default GenerateKeywords;
