import styled from 'styled-components';

export const UploadContainerStyle = styled.div`
  width: 100%;
`;

export const PartnersListStyle = styled.div`
  width: 100%;

  .ant-page-header.ant-page-header-ghost {
    padding: 0 1rem;
    width: 100%;
    display: flex;
  }
`;
