import { CampaignsApi, PaymentApi } from 'utils/apiCalls';
import { handleApiError } from 'utils/helpers';
import moment from 'moment';

export default {
  name: 'costReport',
  state: {
    loading: false,
    error: false,
    errorMsg: '',
    dailyCost: {},
    campaign: {},
    totalCost: 0,
    paymanCost: [],
    totalPaymanCost: 0,
  },
  actions: {
    *getCostReport(campaignId) {
      try {
        yield {
          loading: true,
        };

        const data = yield CampaignsApi.getCampaignById(campaignId).catch(
          e => e,
        );

        handleApiError(data);

        yield {
          campaign: data?.campaign,
        };

        const packageObj = data?.campaign?.package;

        const packageStartDate =
          packageObj?.package_type === 'CUSTOM'
            ? packageObj?.custom_start_date
            : packageObj?.payment_date;

        const packageEndDate =
          packageObj?.package_type === 'CUSTOM'
            ? packageObj?.custom_end_date
            : packageObj?.next_payment_date;

        const paymanCost = yield PaymentApi.getPaymanCost({
          campaignId,
        }).catch(e => e);

        handleApiError(paymanCost);
        const formattedPaymanCost = {};

        // eslint-disable-next-line no-unused-expressions
        paymanCost?.data?.forEach(({ transaction_date, total }) => {
          formattedPaymanCost[transaction_date] =
            total / 100 + (formattedPaymanCost[transaction_date] || 0);
        });

        const totalPaymanCost = Object.values(formattedPaymanCost)?.reduce(
          (acc, cur) => acc + Number(cur),
          0,
        );

        const costReport = yield CampaignsApi.getCostReport({
          campaignId,
          startDate: moment(packageStartDate).format('YYYY-MM-DD'),
          endDate: moment(packageEndDate).format('YYYY-MM-DD'),
          analyticsId: data?.campaign?.analytics_id,
        }).catch(e => e);

        handleApiError(costReport);

        yield {
          dailyCost: costReport?.daily_cost,
          totalCost: costReport?.total_cost,
          totalPaymanCost,
          paymanCost: formattedPaymanCost,
        };

        yield {
          loading: false,
        };
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          loading: false,
          error: true,
          errorMsg,
        };
      }
    },

    *resetState() {
      yield {
        dailyCost: {},
        campaign: {},
      };
    },
  },
};
