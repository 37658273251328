import React, { useMemo } from 'react';
import { Form } from '@ant-design/compatible';
import { Input, Card } from 'antd';
import RangePicker from '../SharedComponents/rangePicker';
import Keywords from '../SharedComponents/keywords';
import GenerateKeywords from '../SharedComponents/generateKeywords';
import Cpc from '../SharedComponents/cpc';

function Bing({
  getFieldDecorator,
  getFieldValue,
  campaign,
  campaignKeywords,
  keywordsLoading,
}) {
  const keywords = getFieldValue('keywords');

  return (
    <>
      <Card title="Bing Details" size="small">
        <Cpc getFieldDecorator={getFieldDecorator} defaultCPC={0.5} />

        <RangePicker
          getFieldDecorator={getFieldDecorator}
          campaign={campaign}
        />

        <Form.Item label="Title Part 1">
          {getFieldDecorator('titlePart1', {
            rules: [
              {
                required: true,
                message: 'Please input Title Part 1!',
              },
            ],
          })(<Input data-test="titlePart1" />)}
        </Form.Item>

        <Form.Item label="Title Part 2">
          {getFieldDecorator('titlePart2', {
            rules: [
              {
                required: true,
                message: 'Please input Title Part 2!',
              },
            ],
          })(<Input data-test="titlePart2" />)}
        </Form.Item>

        <Form.Item label="Title Part 3">
          {getFieldDecorator('titlePart3')(<Input data-test="titlePart3" />)}
        </Form.Item>

        <Form.Item label="Ad Text">
          {getFieldDecorator('adTextT', {
            rules: [
              {
                required: true,
                message: 'Please input an Ad Text!',
              },
            ],
          })(<Input data-test="adText" />)}
        </Form.Item>

        <Form.Item label="Ad Text 2">
          {getFieldDecorator('adTextT2')(<Input data-test="adText2" />)}
        </Form.Item>
      </Card>

      <Card title="Keywords" size="small">
        {useMemo(
          () => (
            <Keywords
              getFieldDecorator={getFieldDecorator}
              campaignKeywords={campaignKeywords}
              keywordsLoading={keywordsLoading}
            />
          ),

          // eslint-disable-next-line react-hooks/exhaustive-deps
          [keywords],
        )}
        <GenerateKeywords getFieldDecorator={getFieldDecorator} />
      </Card>
    </>
  );
}

export default Bing;
