import { LoadingOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
  Button,
  DatePicker,
  Card,
  InputNumber,
  Modal,
  Spin,
  Switch,
} from 'antd';
import { formatDateSemantic } from 'utils/helpers';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import {
  KeyContainerStyle,
  RowContainerStyle,
  ValueContainerStyle,
} from 'pages/CampaignDetails/styles';
import { campaignsStatusMap } from 'utils/constants';
import { ActionContainerStyle } from './styles';

const CampaignActionsPanel = ({
  campaign,
  onEditCampaign,
  fieldsToEdit,
  editCampaignLoading,
}) => {
  const [defaultPaidCampaign, setDefaultPaidCampaign] = useState({});

  useEffect(() => {
    if (campaign && campaign.package) {
      const {
        cpc,
        package: { custom_start_date, custom_end_date, balance },
      } = campaign;

      setDefaultPaidCampaign({
        custom_start_date: moment.utc(custom_start_date),
        custom_end_date: moment.utc(custom_end_date),
        balance,
        cpc,
      });
    }
  }, [campaign]);

  const {
    state,
    allow_optimization: allowOptimization,
    package: {
      auto_renew: autoRenew,
      package_type: packageType,
      renewal_date: renewalDate,
    },
  } = campaign;

  const onToggleOptimization = newValue => {
    onEditCampaign({
      allow_optimization: newValue,
      allow_batch_optimization: newValue,
    });
  };

  const onStopAutoRenewal = () => {
    Modal.confirm({
      title: `Are you sure you want to stop renewal?`,
      width: 700,
      onOk: () =>
        onEditCampaign({
          auto_renew: false,
        }),
      okText: 'Yes',
    });
  };

  const markAsPaid = data => {
    const { cpc, balance, ...packageData } = data;
    let updatedCampaign = { state: 'PENDING_PAID' };
    let updatedPackage = { ...packageData };
    if (cpc && balance) {
      const total_visits = Math.ceil(balance / cpc);

      updatedPackage = { ...updatedPackage, total_visits };
      updatedCampaign = {
        ...updatedCampaign,
        cpc,
      };
    }
    onEditCampaign(updatedCampaign);
    onEditCampaign(updatedPackage);
  };

  const onMarkAsPaid = () => {
    let modalTitle = '';
    let modalContent = '';
    let onOkFunc;
    if (isInstascalerCampaign) {
      modalTitle = 'Are you sure you want to mark campaign as Pending Paid?';

      onOkFunc = () => {
        markAsPaid({
          payment_method: 'offline',
          last_renew_date: moment.utc().format('YYYY-MM-DD'),
          payment_date: moment.utc().format('YYYY-MM-DD'),
          next_payment_date: moment
            .utc()
            .add(1, 'month')
            .format('YYYY-MM-DD'),
        });
      };
    } else {
      const {
        custom_start_date,
        custom_end_date,
        balance,
        cpc,
      } = defaultPaidCampaign;

      onOkFunc = () => {
        markAsPaid({
          payment_method: 'offline',
          last_renew_date: moment.utc(custom_start_date).format('YYYY-MM-DD'),
          custom_start_date: moment.utc(custom_start_date).format('YYYY-MM-DD'),
          custom_end_date: moment.utc(custom_end_date).format('YYYY-MM-DD'),
          balance,
          cpc,
        });
      };

      modalContent = (
        <Card
          title="Please enter the Custom Campaign Data"
          bodyStyle={{ padding: '0 24px' }}
        >
          <RowContainerStyle>
            <KeyContainerStyle>Start Date</KeyContainerStyle>
            <ValueContainerStyle>
              <DatePicker
                defaultValue={moment.utc(custom_start_date)}
                onChange={date =>
                  setDefaultPaidCampaign({
                    ...defaultPaidCampaign,
                    custom_start_date: date,
                  })
                }
                format="YYYY-MM-DD"
                placeholder="Start date"
              />
            </ValueContainerStyle>
          </RowContainerStyle>
          <RowContainerStyle>
            <KeyContainerStyle>End Date</KeyContainerStyle>
            <ValueContainerStyle>
              <DatePicker
                defaultValue={moment.utc(custom_end_date)}
                // disabledDate={this.disableEndDate}
                onChange={date =>
                  setDefaultPaidCampaign({
                    ...defaultPaidCampaign,
                    custom_end_date: date,
                  })
                }
                format="YYYY-MM-DD"
                placeholder="End date"
              />
            </ValueContainerStyle>
          </RowContainerStyle>
          <RowContainerStyle>
            <KeyContainerStyle>Amount</KeyContainerStyle>
            <ValueContainerStyle>
              <InputNumber
                placeholder="Amount"
                min={0}
                onChange={num =>
                  setDefaultPaidCampaign({
                    ...defaultPaidCampaign,
                    balance: num,
                  })
                }
                defaultValue={balance}
              />
            </ValueContainerStyle>
          </RowContainerStyle>
          <RowContainerStyle lastRow>
            <KeyContainerStyle>CPC</KeyContainerStyle>
            <ValueContainerStyle>
              <InputNumber
                placeholder="CPC"
                min={0}
                onChange={num =>
                  setDefaultPaidCampaign({
                    ...defaultPaidCampaign,
                    cpc: num,
                  })
                }
                defaultValue={cpc}
              />
            </ValueContainerStyle>
          </RowContainerStyle>
        </Card>
      );
    }

    Modal.confirm({
      title: modalTitle,
      content: modalContent,
      width: 700,
      onOk: onOkFunc,
      okText: 'Yes',
      icon: <LegacyIcon type={null} />,
    });
  };

  const loadingIcon = <LoadingOutlined style={{ marginLeft: 10 }} spin />;
  const isInstascalerCampaign = packageType !== 'CUSTOM';
  const formattedRenewalDate = formatDateSemantic(renewalDate) || '_';

  const canStopRenewal = !(
    state === 'SUSPENDED' ||
    !isInstascalerCampaign ||
    !autoRenew
  );

  const canMarkAsPaid = state !== 'PENDING_PAID' && state !== 'RUNNING';

  const isEditLoading = fieldKey =>
    editCampaignLoading && fieldsToEdit.includes(fieldKey);

  return (
    <>
      <RowContainerStyle>
        <KeyContainerStyle>Renewal Date</KeyContainerStyle>
        <ValueContainerStyle>
          <ActionContainerStyle>
            <span>
              {autoRenew && isInstascalerCampaign
                ? formattedRenewalDate
                : 'Disabled'}
            </span>
            <Button
              type="primary"
              disabled={!canStopRenewal}
              title={
                !canStopRenewal
                  ? 'Campaign must be an Instascaler campaign that is renewable'
                  : ''
              }
              loading={isEditLoading('auto_renew')}
              onClick={onStopAutoRenewal}
            >
              Stop Renewal
            </Button>
          </ActionContainerStyle>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>Campaign Status</KeyContainerStyle>
        <ValueContainerStyle>
          <ActionContainerStyle>
            <span>{state && campaignsStatusMap[state]?.title}</span>
            <Button
              type="primary"
              disabled={!canMarkAsPaid}
              title={
                !canMarkAsPaid
                  ? "Campaign cannot be 'Running' or 'Pending Paid'"
                  : ''
              }
              loading={isEditLoading('payment_method')}
              onClick={onMarkAsPaid}
            >
              Mark As Paid
            </Button>
          </ActionContainerStyle>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle lastRow>
        <KeyContainerStyle>Optimization</KeyContainerStyle>
        <ValueContainerStyle>
          <Switch checked={allowOptimization} onChange={onToggleOptimization} />
          {isEditLoading('allow_optimization') && (
            <Spin indicator={loadingIcon} />
          )}
        </ValueContainerStyle>
      </RowContainerStyle>
    </>
  );
};

export default CampaignActionsPanel;
