import EditableField from 'components/EditableField';
import React, { useState } from 'react';

import {
  RowContainerStyle,
  KeyContainerStyle,
  ValueContainerStyle,
} from 'pages/CampaignDetails/styles';
import Validation from 'utils/Validation';

import { Form } from '@ant-design/compatible';

import { Input, Radio } from 'antd';

const { Button: RadioButton } = Radio;
const { Group: RadioGroup } = Radio;

const MainInformationPanel = ({
  campaign,
  onEditSave,
  fieldsToEdit,
  editCampaignLoading,
  form,
  editMode,
}) => {
  const [editedValues, setEditedValues] = useState({
    name: '',
    url: '',
    email: '',
    objective: '',
  });

  const { name, url, usersEmails, objective } = campaign;

  const [accountAdmin, partnerAdmin] = [
    usersEmails?.find(user => user.role === 'AccountAdmin')?.['email'],
    usersEmails?.find(user => user.role === 'PartnerAdmin')?.['email'],
  ];

  const userEmail = (
    <p
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
      }}
    >
      {accountAdmin && <span>{accountAdmin || ''}</span>}
      {partnerAdmin && <span>{partnerAdmin || ''}</span>}
    </p>
  );

  const utmSource = campaign.delivery_utm_source || '_';
  const ipAddress = campaign.ip || '_';
  const { notes = '-', IsGARequired = false } =
    (campaign?.customer_requests && JSON.parse(campaign.customer_requests)) ||
    {};

  const isEditLoading = fieldKey =>
    editCampaignLoading && fieldsToEdit.includes(fieldKey);

  const { getFieldDecorator, setFieldsValue } = form;

  const isSaveButtonDisabled = (name, value) =>
    !editedValues[name] || editedValues[name]?.toString() === value?.toString();

  return (
    <>
      <RowContainerStyle>
        <KeyContainerStyle>Name</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            name="name"
            value={name}
            disableSaveButton={isSaveButtonDisabled('name', name)}
            onSave={() => onEditSave({ name: editedValues['name'] })}
            onCancel={() => {
              setEditedValues({ ...editedValues, name });
              setFieldsValue({ name });
            }}
            loadingEdit={isEditLoading('name')}
            editMode={editMode}
          >
            <Form.Item>
              {getFieldDecorator('name', {
                initialValue: name,
                rules: [Validation.required],
              })(
                <Input
                  placeholder="Name"
                  onChange={e =>
                    setEditedValues({
                      ...editedValues,
                      name: e.target.value,
                    })
                  }
                  data-test={`name-input`}
                />,
              )}
            </Form.Item>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>URL</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            clickable
            value={url}
            disableSaveButton={isSaveButtonDisabled('url', url)}
            onSave={() => onEditSave({ url: editedValues['url'] })}
            onCancel={() => {
              setEditedValues({ ...editedValues, url });
              setFieldsValue({ url });
            }}
            loadingEdit={isEditLoading('url')}
            editMode={editMode}
          >
            <Form.Item>
              {getFieldDecorator('url', {
                initialValue: url,
                rules: [Validation.required],
              })(
                <Input
                  placeholder="URL"
                  onChange={e =>
                    setEditedValues({
                      ...editedValues,
                      url: e.target.value,
                    })
                  }
                  data-test={`url-input`}
                />,
              )}
            </Form.Item>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>Emails</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField value={userEmail} disableEdit>
            <Form.Item>
              {getFieldDecorator('email', {
                initialValue: userEmail,
                rules: [Validation.required, Validation.email],
              })(
                <Input
                  placeholder="Email"
                  onChange={e =>
                    setEditedValues({
                      ...editedValues,
                      email: e.target.value,
                    })
                  }
                  data-test={`email-input`}
                />,
              )}
            </Form.Item>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>Objective</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            value={objective}
            disableSaveButton={isSaveButtonDisabled('objective', objective)}
            onSave={() => onEditSave({ objective: editedValues['objective'] })}
            onCancel={() => {
              setEditedValues({ ...editedValues, objective });
              setFieldsValue({ objective });
            }}
            loadingEdit={isEditLoading('email')}
            editMode={editMode}
          >
            <RadioGroup
              onChange={e =>
                setEditedValues({
                  ...editedValues,
                  objective: e.target.value,
                })
              }
              value={editedValues['objective'] || objective}
            >
              <RadioButton value="awareness">Awareness</RadioButton>
              <RadioButton value="conversions">Conversions</RadioButton>
            </RadioGroup>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>UTM Source</KeyContainerStyle>
        <ValueContainerStyle>{utmSource}</ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>IP Address</KeyContainerStyle>
        <ValueContainerStyle>{ipAddress}</ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>Is GA Required?</KeyContainerStyle>
        <ValueContainerStyle>{String(IsGARequired)}</ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle lastRow>
        <KeyContainerStyle>Special Instructions</KeyContainerStyle>
        <ValueContainerStyle>{notes}</ValueContainerStyle>
      </RowContainerStyle>
    </>
  );
};

export default Form.create()(MainInformationPanel);
