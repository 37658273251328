import { UsersApi } from 'utils/apiCalls';
import { handleApiError } from 'utils/helpers';

export default {
  name: 'Users',
  state: {
    getAllUsersLoading: false,
    usersList: [],
    usersCount: 0,
  },
  actions: {
    *getUsers(urlParams) {
      yield { getAllUsersLoading: true };

      try {
        const getAllUsers = yield UsersApi.getUsers(urlParams).catch(e => e);
        handleApiError(getAllUsers);

        yield {
          usersCount: getAllUsers.headers['x-count'],
          usersList: getAllUsers.data,
          getAllUsersLoading: false,
        };
      } catch (err) {
        const { message } = err;
        yield {
          errorMessage: message,
          getAllUsersLoading: false,
          usersList: [],
        };
      }
    },
  },
};
