import {
  NotificationOutlined,
  TeamOutlined,
  UserOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { pathAllCampaigns, pathPartners, pathUsers, pathAllNotifications } from 'routes/routes';
import styled from 'styled-components';
import useMedia from 'use-media';
import { MOB_SCREEN_WIDTH } from 'utils/constants';

const { Sider } = Layout;

const LogoPlaceHolder = styled.div`
  height: 3.2rem;
  background: rgba(255, 255, 255, 0.2);
  margin: 1.6rem;
`;

const MenuContainer = styled.div`
  // do not override on configs coz we don't need to override all menus on the app, this is for side nav only
  .ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item {
    height: 4.5rem !important;
    line-height: 4.5rem !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
`;

const SideNav = props => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const isMob = useMedia({ maxWidth: MOB_SCREEN_WIDTH });

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sider
      {...(isMob ? { breakpoint: 'sm', collapsedWidth: '0' } : {})}
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapse}
    >
      <LogoPlaceHolder />
      <MenuContainer>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={location.pathname}
          selectedKeys={location.pathname}
        >
          <Menu.Item key={pathAllCampaigns}>
            <Link to={pathAllCampaigns}>
              <NotificationOutlined style={{ fontSize: '2rem' }} />
              <span>Campaigns</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={pathPartners} data-test="partners-list">
            <Link to={pathPartners}>
              <TeamOutlined style={{ fontSize: '2rem' }} />
              <span>Partners</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={pathUsers}>
            <Link to={pathUsers}>
              <UserOutlined style={{ fontSize: '2rem' }} />
              <span>Users</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={pathAllNotifications}>
            <Link to={pathAllNotifications}>
              <BellOutlined style={{ fontSize: '2rem' }} />
              <span>Notifications</span>
            </Link>
          </Menu.Item>
        </Menu>
      </MenuContainer>
    </Sider>
  );
};

export default SideNav;
