import { Table } from 'antd';
import React from 'react';

const CustomizedTable = ({
  columns,
  data,
  pagination,
  maxHeight,
  hasMinWidth,
  ...props
}) => {
  const paginationDefaultConfig = {
    position: 'bottom',
    pageSize: 20,
    size: 'small',
  };
  return (
    <Table
      scroll={{
        y: maxHeight ? '50vh' : 300,
        scrollToFirstRowOnChange: true,
        x: hasMinWidth ? 800 : false,
      }}
      columns={columns}
      dataSource={data}
      pagination={{ ...paginationDefaultConfig, ...pagination }}
      {...props}
    />
  );
};

export default CustomizedTable;
