import { CampaignsApi } from 'utils/apiCalls';
import * as helpers from 'utils/helpers';
import theme from 'styles/themes';
import { handleApiError } from 'utils/helpers';
import { showMessage } from 'utils/helpers';
import moment from 'moment';
import { COOKIES_NOTIFICATION_LAST_CHK } from '../../utils/constants';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
export default {
  name: 'topNav',

  state: {
    loading: false,
    error: false,
    updateCampaignsCount: false,
    errorMsg: '',
    campaignsStateCount: [],
    notifications: [],
    getNotificationsLoading: false,
    newMsgCount: 0,
  },

  actions: {
    *getCampaignsStateCount() {
      try {
        const campaignsStateCountResponse = yield CampaignsApi.getCampaignsStateCount().catch(
          e => e,
        );

        helpers.handleApiError(campaignsStateCountResponse);

        const stateColorMapper = {
          RUNNING: theme.colors.green,
          PENDING_PAID: theme.colors.blue,
          PAUSED: theme.colors.orange,
        };

        const campaignsStateCount = [];

        Object.keys(stateColorMapper).forEach(state => {
          campaignsStateCount.push({
            name: state,
            value: campaignsStateCountResponse[state] || 0,
            color: stateColorMapper[state],
          });
        });

        yield {
          campaignsStateCount,
          updateCampaignsCount: false,
        };
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          loading: false,
          updateCampaignsCount: false,
          error: true,
          errorMsg,
        };
      }
    },
    *getCampaignNotifications() {
      try {
        yield { getNotificationsLoading: true };

        const notifications = yield CampaignsApi.getCampaignNotifications(
          null,
          'ALL',
        ).catch(e => e);

        handleApiError(notifications);

        let newMsgCount = 0;

        const dropdownNotifications = notifications.slice(0, 20);

        const lastChkNotificationOn = cookies.get(
          COOKIES_NOTIFICATION_LAST_CHK,
        );

        dropdownNotifications.forEach(notification => {
          const notificationDate = moment.utc(notification.inserted_on);
          const lastChkDate = moment.utc(lastChkNotificationOn);

          notificationDate.isAfter(lastChkDate) && newMsgCount++;
        });

        yield {
          getNotificationsLoading: false,
          notifications: dropdownNotifications,
          newMsgCount,
        };
      } catch (err) {
        const { message } = err;
        yield {
          getNotificationsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching Notifications${message ? `: ${message}` : ''}`,
        );
      }
    },
    resetNotifications() {
      return {
        notifications: [],
      };
    },
    resetCount() {
      return {
        newMsgCount: 0,
      };
    },
  },
  handlers: {
    '@@Campaigns/GET_ALL_CAMPAIGNS/COMPLETE': function() {
      return {
        updateCampaignsCount: true,
      };
    },
  },
};
