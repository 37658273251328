// Public paths
export const pathRoot = '/';
export const pathLogin = '/login';
export const pathForgetPassword = '/forget';

// Authorized paths
export const pathAllCampaigns = '/campaigns';
export const pathPartners = '/partners';
export const pathUsers = '/users';
export const pathHomePage = pathAllCampaigns;
export const pathAllNotifications = '/notifications';
