import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
  ButtonsContainerStyle,
  EditModeContainerStyle,
  ShownValueStyle,
  ViewModeContainerStyle,
} from './style';
import connect from 'speedux/lib/connect';
import Module from './module';

const EditableField = ({
  clickable,
  name,
  value,
  onSave,
  onCancel,
  renderShownValue,
  loadingEdit,
  disableEdit,
  children,
  editMode,
  disableSaveButton,
  globalState: { campaign },
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showedValue, setShowValue] = useState('');

  useEffect(() => {
    if (editMode === false) {
      setIsEditing(false);
    }
  }, [editMode]);

  const onCancelEdit = () => {
    setIsEditing(false);
    onCancel();
  };

  const showValue = inputValue => {
    if (renderShownValue) {
      return renderShownValue(inputValue);
    }
    if (Array.isArray(inputValue)) {
      return inputValue.map((element, i) => {
        const putComma = i !== inputValue.length - 1;
        return `${element}${putComma ? ', ' : ''}`;
      });
    }
    return value || value === 0 ? value : '_';
  };

  useEffect(() => {
    const storedValue = showValue(value);
    setShowValue(storedValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (isEditing) {
    return (
      <EditModeContainerStyle>
        {children}
        <ButtonsContainerStyle>
          <Button onClick={onCancelEdit}>Cancel</Button>
          <Button
            type="primary"
            onClick={onSave}
            loading={loadingEdit}
            disabled={disableSaveButton}
            data-test={`${name}-save-button`}
          >
            Save
          </Button>
        </ButtonsContainerStyle>
      </EditModeContainerStyle>
    );
  } else {
    return (
      <ViewModeContainerStyle>
        <ShownValueStyle data-test={`${name}-value`}>
          {clickable ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${
                showedValue === '_'
                  ? '/'
                  : showedValue.slice(0, 4) === 'http'
                  ? showedValue
                  : `https://${showedValue}`
              }`}
            >
              {showedValue}
            </a>
          ) : (
            showedValue
          )}
        </ShownValueStyle>
        {!disableEdit && campaign?.state !== 'ARCHIVED' && (
          <span data-test={`${name}-edit-icon`}>
            <EditOutlined
              title="Click to Edit"
              style={{ fontSize: 18 }}
              onClick={() => setIsEditing(true)}
            />
          </span>
        )}
      </ViewModeContainerStyle>
    );
  }
};

EditableField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.any),
  ]),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  loadingEdit: PropTypes.bool,
  disableEdit: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  // editMode prop is optional
  editMode: PropTypes.bool,
  disableSaveButton: PropTypes.bool,
  clickable: PropTypes.bool,
};

EditableField.defaultProps = {
  clickable: false,
  name: '',
  value: '',
  onSave: () => null,
  onCancel: () => null,
  renderShownValue: null,
  loadingEdit: false,
  disableEdit: false,
  disableSaveButton: false,
};

export default connect(EditableField, Module);
