import React, { useEffect, useState } from 'react';
import { ExportOutlined } from '@ant-design/icons';
import {
  Card,
  DatePicker,
  Select,
  PageHeader,
  Menu,
  Dropdown,
  Button,
  Table,
  Spin,
  Popover,
  Tag,
} from 'antd';
import {
  PageHeaderStyles,
  FilterGaTableStyles,
  TableFixedStyles,
} from './styles';
import {
  predefinedDateRanges,
  downloadJSONFile,
  downloadCSV,
} from 'utils/helpers';
import { DATE_FORMAT } from 'utils/constants';
import Module from './module';
import { connect } from 'speedux';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;
function GaIntegration({
  campaign: {
    name: campaignName,
    url: campaignUrl,
    id: campaignId,
    deliveryUtmSource,
  },
  actions: { getUTMSources, getUTMSourceKeywordText, resetState },
  state: {
    UTMSources,
    rank,
    formattedData,
    tableCols,
    loading,
    loadingUTMKeywordText,
    UTMKeywordsList,
  },
}) {
  const [selectedUtmSource, selectUtmSource] = useState(deliveryUtmSource);

  useEffect(() => {
    selectUtmSource(deliveryUtmSource);
  }, [deliveryUtmSource, campaignId]);

  const [selectedDateRange, setDateRange] = useState([
    moment
      .utc()
      .subtract(30, 'days')
      .format(DATE_FORMAT),
    moment.utc().format(DATE_FORMAT),
  ]);

  const onDownload = ({ key }) => {
    if (key === 'json') {
      downloadJSONFile(formattedData, 'Campaign Log');
    } else if (key === 'csv') {
      downloadCSV(formattedData, 'Campaign Log');
    }
  };

  const renderExportMenu = () => {
    return (
      <Menu onClick={onDownload}>
        <Menu.Item key="csv">CSV</Menu.Item>
        <Menu.Item key="json">JSON</Menu.Item>
      </Menu>
    );
  };

  const renderUtmSources = () => {
    return UTMSources.map(({ source, sessions }) => (
      <Option value={source} key={source}>{`${source} (${sessions})`}</Option>
    ));
  };

  const formatTableColumns = tableColumns => {
    const formattedTableColumns = [...tableColumns];
    const utmTermColumn = formattedTableColumns?.[0]?.children?.[0];

    if (!utmTermColumn) {
      return tableColumns;
    }

    const getUTMIDs = ({ utmTerm, utmMedium }) => {
      const keywordId = utmTerm.split(/[-:]+/)[2];
      const accountId =
        utmMedium.split('--')[1] &&
        utmMedium
          .split('--')[1]
          .split('-')
          .splice(0, 3)
          .join('-');
      const adgroupId = utmMedium
        .split('--')[0]
        .split('-')
        .pop();

      return {
        keywordId: Number.isInteger(Number(keywordId)) && keywordId,
        accountId,
        adgroupId: Number.isInteger(Number(adgroupId)) && adgroupId,
      };
    };

    utmTermColumn.render = (utmTerm, row) => {
      const utmMedium = row['ga:medium'];

      const utmIDs = getUTMIDs({ utmTerm, utmMedium });

      const utmHasKeywordText =
        utmIDs.keywordId && utmIDs.accountId && utmIDs.adgroupId;

      if (utmHasKeywordText) {
        let popoverContent = '';
        if (loadingUTMKeywordText) {
          popoverContent = <Spin />;
        } else {
          const UTMkeywordSaved = UTMKeywordsList.find(
            savedUTM =>
              savedUTM.keywordId === utmIDs.keywordId &&
              savedUTM.accountId === utmIDs.accountId &&
              savedUTM.adgroupId === utmIDs.adgroupId,
          );

          popoverContent = UTMkeywordSaved?.text;
        }
        return (
          <span>
            <span>{utmTerm}</span>
            <Popover
              content={popoverContent}
              onVisibleChange={visible =>
                visible && getUTMSourceKeywordText(utmIDs)
              }
            >
              <Tag style={{ fontWeight: 'bold' }}>?</Tag>
            </Popover>
          </span>
        );
      }

      return utmTerm;
    };

    return formattedTableColumns;
  };

  useEffect(() => {
    const [startDate, endDate] = selectedDateRange;

    getUTMSources({
      startDate,
      endDate,
      campaignId,
      utmFilter: selectedUtmSource,
      keywords: true,
    });

    return () => resetState();
  }, [campaignId, selectedUtmSource, selectedDateRange]); //eslint-disable-line

  return (
    <Card>
      <PageHeaderStyles>
        <PageHeader title={campaignName} subTitle={campaignUrl} />
      </PageHeaderStyles>
      <div>
        <FilterGaTableStyles>
          <div>
            UTM Source:{' '}
            <Select
              showSearch
              style={{ minWidth: 200 }}
              value={selectedUtmSource}
              placeholder="Select utm source"
              optionFilterProp="children"
              onChange={selectUtmSource}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {renderUtmSources()}
            </Select>
          </div>
          <div>
            <b style={{ fontSize: '2rem' }}>Rank: {rank}</b>/{UTMSources.length}
          </div>
        </FilterGaTableStyles>
        <FilterGaTableStyles>
          <div>
            Date:{' '}
            <RangePicker
              ranges={predefinedDateRanges}
              format={DATE_FORMAT}
              placeholder={[
                moment
                  .utc()
                  .subtract(30, 'days')
                  .format(DATE_FORMAT),
                moment.utc().format(DATE_FORMAT),
              ]}
              onChange={(_, val) => setDateRange(val)}
            />
          </div>
          <Dropdown overlay={renderExportMenu()}>
            <Button icon={<ExportOutlined />}>Export</Button>
          </Dropdown>
        </FilterGaTableStyles>
      </div>

      <TableFixedStyles>
        <Table
          columns={formatTableColumns(tableCols)}
          dataSource={formattedData}
          loading={loading}
          bordered
          size="middle"
          pagination={{ defaultPageSize: 100, size: 'small' }}
          scroll={{ x: 300 }}
        />
      </TableFixedStyles>
    </Card>
  );
}

export default connect(GaIntegration, Module);
