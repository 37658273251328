import {
  FacebookOutlined,
  GoogleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Collapse, Modal, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';

import {
  CollapseContainerStyle,
  CollapsePanelHeaderStyle,
} from 'pages/CampaignDetails/styles';

import FacebookIntegrationPanel from './FacebookIntegrationPanel';
import GoogleIntegrationPanel from './GoogleIntegrationPanel';
import OpentagPanel from './OpentagPanel';
import { LinkContainerStyle, CopyButtonContainerStyle } from './styles';

const { Panel } = Collapse;
const { Paragraph } = Typography;

const Analytics = ({
  campaign,
  opentagScripts,
  getGaGoals,
  getCampaignScripts,
  gaGoals,
  gaViews,
  getCampaignGAViews,
  patchCampaignGAView,
  editOpentagScripts,
  fetchCampaignGAViewsLoading,
  fetchCampaignScriptsLoading,
  patchCampaignGAViewLoading,
  fetchCampaignGoalsLoading,
  editCampaignOpenTagLoading,
  generateIntegrationToken,
  generateGaIntegrationTokenLoading,
  generateFbIntegrationTokenLoading,
  gaIntegrationToken,
  fbIntegrationToken,
  subSectionTabSelected,
  selectSubSectionFromTab,
  getCampaignIntegrations,
  fetchCampaignIntegrationsLoading,
  selectedTabKey,
}) => {
  useEffect(() => {
    if (selectedTabKey === '5' && campaign._id) {
      getGaGoals();
      getCampaignGAViews();
      getCampaignScripts();
      getCampaignIntegrations();
    }
  }, [campaign._id]); //eslint-disable-line

  const {
    is_ga_integrated: isGaIntegrated,
    is_fb_integrated: isFbIntegrated,
  } = campaign;

  const showIntegrationLink = ({ title, link }) => {
    Modal.info({
      title,
      content: (
        <>
          <LinkContainerStyle>{link}</LinkContainerStyle>
          <CopyButtonContainerStyle>
            <Paragraph copyable={{ text: link }} />
          </CopyButtonContainerStyle>
        </>
      ),
      width: 700,
    });
  };

  if (fetchCampaignIntegrationsLoading) {
    const loadingIcon = <LoadingOutlined spin />;
    return (
      <Spin indicator={loadingIcon} style={{ width: '100%', margin: 20 }} />
    );
  }

  return (
    <CollapseContainerStyle>
      <Collapse
        defaultActiveKey={[subSectionTabSelected]}
        expandIconPosition="right"
        onChange={selectSubSectionFromTab}
        accordion
      >
        <Panel
          header={
            <CollapsePanelHeaderStyle>OpenTag Scripts</CollapsePanelHeaderStyle>
          }
          key="1"
        >
          <OpentagPanel
            campaign={campaign}
            opentagScripts={opentagScripts}
            fetchCampaignScriptsLoading={fetchCampaignScriptsLoading}
            editOpentagScripts={editOpentagScripts}
            editCampaignOpenTagLoading={editCampaignOpenTagLoading}
          />
        </Panel>
        <Panel
          header={
            <CollapsePanelHeaderStyle>
              Google Integration
              <GoogleOutlined style={{ fontSize: 20, marginLeft: 10 }} />
            </CollapsePanelHeaderStyle>
          }
          key="2"
        >
          <GoogleIntegrationPanel
            campaign={campaign}
            generateIntegrationToken={generateIntegrationToken}
            getTokenLoading={generateGaIntegrationTokenLoading}
            integrationToken={gaIntegrationToken}
            isIntegrated={isGaIntegrated}
            showIntegrationLink={showIntegrationLink}
            getGAViewsLoading={fetchCampaignGAViewsLoading}
            patchGAView={patchCampaignGAView}
            patchGAViewLoading={patchCampaignGAViewLoading}
            fetchCampaignGoalsLoading={fetchCampaignGoalsLoading}
            goals={gaGoals}
            views={gaViews}
          />
        </Panel>
        <Panel
          header={
            <CollapsePanelHeaderStyle>
              Facebook Integration
              <FacebookOutlined style={{ fontSize: 20, marginLeft: 10 }} />
            </CollapsePanelHeaderStyle>
          }
          key="3"
        >
          <FacebookIntegrationPanel
            campaign={campaign}
            generateIntegrationToken={generateIntegrationToken}
            getTokenLoading={generateFbIntegrationTokenLoading}
            integrationToken={fbIntegrationToken}
            isIntegrated={isFbIntegrated}
            showIntegrationLink={showIntegrationLink}
          />
        </Panel>
      </Collapse>
    </CollapseContainerStyle>
  );
};

export default Analytics;
