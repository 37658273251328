import { CampaignsApi } from 'utils/apiCalls';
import { handleApiError, showMessage } from 'utils/helpers';

export default {
  name: 'History',
  state: {
    getLogsLoading: false,
    getWorkflowRunsLoading: false,
    historyLogs: [],
    workflowRuns: [],
  },
  actions: {
    *getCampaignLogs(campaignId) {
      yield { getLogsLoading: true };
      try {
        const logs = yield CampaignsApi.getCampaignHistory(campaignId).catch(
          e => e,
        );
        handleApiError(logs);

        yield {
          getLogsLoading: false,
          historyLogs: logs,
        };
      } catch (err) {
        const { message } = err;
        yield {
          getLogsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching campaign logs${message ? `: ${message}` : ''}`,
        );
      }
    },

    *getCampaignWorkflowRuns(campaignId) {
      yield { getWorkflowRunsLoading: true };
      try {
        const data = yield CampaignsApi.getCampaignWorkflowRuns(
          campaignId,
        ).catch(e => e);
        handleApiError(data);

        yield {
          getWorkflowRunsLoading: false,
          workflowRuns: data,
        };
      } catch (err) {
        yield {
          getWorkflowRunsLoading: false,
        };
      }
    },
  },
};
