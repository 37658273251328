import { CampaignsApi, UploadApi } from 'utils/apiCalls';
import { handleApiError } from 'utils/helpers';

export default {
  name: 'publishCampaign',
  state: {
    publishCampaignLoading: false,
    isPublishCampaignSuccess: false,
    publishedCampaign: {},
    publishCampaignErrorMessage: '',

    uploadedSuccessfully: false,
    errorUpload: false,
    errorUploadMsg: '',
    generatedURL: '',

    getTrafficSourceAccountsLoading: false,
    trafficSourceAccountsSuccess: false,
    trafficSourceAccountList: [],
    getTrafficSourceAccountsErrorMessage: '',

    finalUrlData: {},
    getFinalUrlError: false,

    getVerticalsLoading: false,
    verticalsSuccess: false,
    verticalsList: [],
    getVerticalsErrorMessage: '',

    allFbAudiences: [],
    allPagesIds: [],
    isAudianceListLoading: false,
    isPageLoading: false,
    error: false,
    errorMsg: '',
    campaignKeywords: '',
    getKeywordsErrorMessage: '',
    keywordsLoading: false,
  },
  actions: {
    *getKeywords(kampaniaLink) {
      try {
        yield {
          keywordsLoading: true,
        };
        const kampaniaObj = yield CampaignsApi.getCampaignKeywords(
          kampaniaLink,
        ).catch(e => e);

        handleApiError(kampaniaObj);

        yield {
          keywordsLoading: false,
          campaignKeywords: kampaniaObj.keywords,
        };
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          keywordsLoading: false,
          getKeywordsErrorMessage: errorMsg,
        };
      }
    },
    *publishCampaign(campaignId, options) {
      try {
        yield { publishCampaignLoading: true };
        const publishedCampaign = yield CampaignsApi.publishCampaign(
          campaignId,
          options,
        ).catch(e => e);
        handleApiError(publishedCampaign);
        yield {
          publishCampaignLoading: false,
          isPublishCampaignSuccess: true,
          publishedCampaign,
        };
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          publishCampaignLoading: false,
          publishCampaignErrorMessage: errorMsg,
          isPublishCampaignSuccess: false,
          paignSuccess: false,
        };
      }
    },

    *generatePresignedUrl(campaignId) {
      try {
        const result = yield UploadApi.generatePresignedUrl(campaignId).catch(
          e => e,
        );
        handleApiError(result);
        const generatedURL = result?.url;
        yield {
          generatedURL,
        };
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          errorUpload: true,
          errorUploadMsg: errorMsg,
        };
      }
    },

    uploadFile({ action, file, headers, onSuccess, onError }) {
      fetch(action, {
        method: 'PUT',
        body: file,
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            onSuccess(res, file);
          }
        })
        .catch(error => {
          onError(error);
        });
    },

    *getTrafficSourceAccounts() {
      try {
        yield { getTrafficSourceAccountsLoading: true };
        const trafficSourceAccountListResult = yield CampaignsApi.getTrafficSourceAccounts().catch(
          e => e,
        );
        handleApiError(trafficSourceAccountListResult);
        yield {
          trafficSourceAccountList: trafficSourceAccountListResult,
          getTrafficSourceAccountsLoading: false,
          trafficSourceAccountsSuccess: true,
        };
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          getTrafficSourceAccountsLoading: false,
          getTrafficSourceAccountsErrorMessage: errorMsg,
        };
      }
    },

    *getFinalUrl(url) {
      try {
        yield { finalUrlData: {} };
        const response = yield CampaignsApi.getFinalUrl(url).catch(e => e);
        handleApiError(response);
        const finalUrlData = response.data;
        yield {
          finalUrlData,
          getFinalUrlError: false,
        };
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          getFinalUrlError: errorMsg,
        };
      }
    },

    *getVerticals() {
      try {
        yield { getVerticalsLoading: true };
        const verticalsListResult = yield CampaignsApi.getVerticals().catch(
          e => e,
        );
        handleApiError(verticalsListResult);
        yield {
          verticalsList: verticalsListResult?.verticals,
          getVerticalsLoading: false,
          verticalsSuccess: true,
        };
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          getVerticalsLoading: false,
          getVerticalsErrorMessage: errorMsg,
        };
      }
    },

    *getAudienceIds(trafficSource) {
      try {
        if (trafficSource) {
          yield { isAudianceListLoading: true };
          const result = yield CampaignsApi.getAudiences(trafficSource).catch(
            e => e,
          );
          handleApiError(result);
          yield {
            allFbAudiences: result,
            isAudianceListLoading: false,
          };
        }
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          error: true,
          errorMsg,
          canCloseModal: true,
          isAudianceListLoading: false,
        };
      }
    },

    *getPageIds(campaignId) {
      try {
        yield { isPageLoading: true };
        const result = yield CampaignsApi.getPages(campaignId).catch(e => e);
        handleApiError(result);
        yield {
          allPagesIds: result,
          isPageLoading: false,
        };
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          error: true,
          errorMsg,
          canCloseModal: true,
          isPageLoading: false,
        };
      }
    },

    resetUpload() {
      return { uploadedSuccessfully: false };
    },

    *resetState() {
      yield {
        publishCampaignLoading: false,
        isPublishCampaignSuccess: true,
        publishedCampaign: {},
        publishCampaignErrorMessage: '',

        uploadedSuccessfully: false,
        errorUpload: false,
        errorUploadMsg: '',

        getKeywordsErrorMessage: '',
      };
    },

    resetKeywords() {
      return {
        campaignKeywords: '',
      };
    },
  },
};
