import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import React, { useEffect } from 'react';

import WfRunsTable from './WfRunsTable';

const Publishing = ({
  campaign,
  campaignWorkflowRuns,
  tsCampaignsReport,
  accountTransactions,
  cloneCampaign,
  getCampaignWorkflowRuns,
  cloneCampaignLoading,
  fetchCampaignWorkflowRunsLoading,
  fetchTsCampaignsLoading,
  setPublishCampaignDrawerVisible,
  editTSCampaignWeight,
  editWeightLoading,
  doneEditingWeight,
}) => {
  useEffect(() => {
    getCampaignWorkflowRuns();
  }, [campaign._id]); //eslint-disable-line

  const campaignId = campaign?._id;

  const loadingIcon = <LoadingOutlined spin />;

  const loading = fetchCampaignWorkflowRunsLoading || fetchTsCampaignsLoading;

  return (
    <>
      {campaignId ? (
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => setPublishCampaignDrawerVisible(true)}
            type="primary"
            data-test="open-publish-button"
            style={{ marginBottom: 20 }}
          >
            Publish Campaign
          </Button>
        </div>
      ) : (
        ''
      )}

      {loading ? (
        <Spin indicator={loadingIcon} style={{ width: '100%', margin: 10 }} />
      ) : (
        campaignWorkflowRuns?.length > 0 && (
          <WfRunsTable
            campaign={campaign}
            campaignWorkflowRuns={campaignWorkflowRuns}
            tsCampaignsReport={tsCampaignsReport}
            accountTransactions={accountTransactions}
            cloneCampaign={cloneCampaign}
            cloneCampaignLoading={cloneCampaignLoading}
            editTSCampaignWeight={editTSCampaignWeight}
            editWeightLoading={editWeightLoading}
            doneEditingWeight={doneEditingWeight}
          />
        )
      )}
    </>
  );
};

export default Publishing;
