import React, { useMemo, useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Card, Menu, Dropdown, Checkbox, Alert } from 'antd';
import useMedia from 'use-media';
import JSZip from 'jszip';
import {
  MOB_SCREEN_WIDTH,
  MOB_WIDTH_FOR_DRAWER,
  flexBetweenCenter,
  DATE_FORMAT,
} from 'utils/constants';
import PropTypes from 'prop-types';
import { PublishCampaignCardsStyle } from './styles';
import { formatList, renderKeywords, showMessage } from 'utils/helpers';
import Bing from './Bing';
import Propeller from './Propeller';
import GoogleSearch from './GoogleSearch';
import GoogleDisplay from './GoogleDisplay';
import Facebook from './Facebook';
import { connect } from 'speedux';
import Module from './module';
import { FormContainerStyle } from 'styles/styledComponents';
import { filterTrafficSources, trafficSourcesOptions } from './constants';
import moment from 'moment';
import ZeroPark from './ZeroPark';
import WeightSlider from './SharedComponents/weightSlider';

function PublishCampaign({
  isPublishCampaignDrawerVisible,
  setPublishCampaignDrawerVisible,
  campaign,
  campaignLinks,
  campaignCreatives,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    getFieldValue,
    setFieldsValue,
    resetFields,
  },
  actions: {
    publishCampaign,
    generatePresignedUrl,
    uploadFile,
    resetUpload,
    resetState,
    getTrafficSourceAccounts,
    getFinalUrl,
    getVerticals,
    getAudienceIds,
    getPageIds,
    getKeywords,
    resetKeywords,
  },
  state: {
    publishCampaignLoading,
    isPublishCampaignSuccess,
    generatedURL,
    uploadedSuccessfully,
    publishCampaignErrorMessage,
    publishedCampaign,
    trafficSourceAccountList,
    getTrafficSourceAccountsLoading,
    finalUrlData,
    verticalsList,
    getVerticalsLoading,
    allFbAudiences,
    allPagesIds,
    isAudianceListLoading,
    isPageLoading,
    campaignKeywords,
    keywordsLoading,
  },
}) {
  const filteredTrafficSources = campaign && filterTrafficSources(campaign);
  const isMob = useMedia({ maxWidth: MOB_SCREEN_WIDTH });
  const [selectedTrafficSource, selectTrafficSource] = useState('');
  const [weight, setWeight] = useState(100);
  const [uploadFilesData, setUploadFilesData] = useState({});

  const base64ImageToBlob = ({ name, extension, base64 }) => {
    // extract content type and base64 payload from original string
    const type = extension;
    const b64 = base64;

    // decode base64
    const imageContent = atob(b64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (let n = 0; n < imageContent.length; n++) {
      // eslint-disable-line
      view[n] = imageContent.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    const blob = new File([buffer], name, { type });

    return blob;
  };

  const handleDownloadOldFile = banners => {
    const filesToUpload = banners.map(base64ImageToBlob);

    const zip = new JSZip();

    filesToUpload.forEach(img => zip.file(img.name, img, { base64: true }));

    zip.generateAsync({ type: 'blob' }).then(content => {
      content.lastModifiedDate = new Date(); // eslint-disable-line
      content.name = 'images.zip'; // eslint-disable-line

      setUploadFilesData({
        zip: content,
        filesToUpload: [{ uid: 0, name: 'images.zip', ...zip }],
      });
    });
  };

  const resetCurrentState = () => {
    setWeight(100);
    selectTrafficSource('');
  };

  const reformatTrafficSourceNaming = trafficSrc => {
    if (trafficSrc.includes('zp')) {
      return 'zp';
    } else if (trafficSrc.includes('propeller')) {
      return 'propeller';
    }
    return trafficSrc;
  };

  const validateAndSubmit = (err, formValues) => {
    if (!err) {
      let modifiedValues = {
        ...formValues,
        legacyMode:
          filteredTrafficSources[selectedTrafficSource].legacyMode || false,
        on_semaphore:
          filteredTrafficSources[selectedTrafficSource].on_semaphore || false,
        publish_testing_campaign:
          filteredTrafficSources[selectedTrafficSource]
            .publish_testing_campaign || false,
        trafficSource: reformatTrafficSourceNaming(selectedTrafficSource),
      };

      if ('keywords' in formValues) {
        modifiedValues.keywords = formValues.keywords.join(';');
      }

      if ('adTextT' in formValues) {
        delete modifiedValues.adTextT;
        modifiedValues.adText = formValues.adTextT;
      }

      if ('adTextT2' in formValues) {
        delete modifiedValues.adTextT2;
        modifiedValues.adText2 = formValues.adTextT2;
      }

      if ('weight' in formValues) {
        modifiedValues.weight /= 100;
      }

      if ('rangePicker' in formValues) {
        delete modifiedValues.rangePicker;
        modifiedValues['start_date'] = moment
          .utc(formValues.rangePicker[0])
          .format(DATE_FORMAT);
        modifiedValues['end_date'] = moment
          .utc(formValues.rangePicker[1])
          .format(DATE_FORMAT);
      }

      if (selectedTrafficSource === 'googleSearch') {
        modifiedValues.trafficSource =
          formValues.trafficSource === 'responsive'
            ? 'google_responsive'
            : 'google';
      }

      // TODO
      if (selectedTrafficSource === 'googleDisplay') {
        modifiedValues.trafficSource = 'google_display';
        modifiedValues.keywords = 'google;';
      }

      if (selectedTrafficSource === 'facebook') {
        const headlinesObject = Object.assign(
          {},
          ...formValues.headlines.map((val, index) => ({
            [`headlinePart${index + 1}`]: val,
          })),
        );

        const description = Object.assign(
          {},
          ...formValues.description.map((val, index) => ({
            [`description${index === 0 ? '' : index + 1}`]: val,
          })),
        );

        delete modifiedValues.headlines;
        delete modifiedValues.description;
        modifiedValues = {
          ...modifiedValues,
          ...headlinesObject,
          ...description,
        };
      }

      delete modifiedValues.keysHeadlines;
      delete modifiedValues.keysDescriptions;

      publishCampaign(campaign?._id, modifiedValues);
    }
  };

  const handlePublishCampaignSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, formValues) => {
      validateAndSubmit(err, formValues);
    });
  };

  const renderSourcesMenu = useMemo(() => {
    return (
      <Menu
        onClick={({ key: trafficSourceKey }) => {
          selectTrafficSource(trafficSourceKey);
        }}
      >
        {Object.keys(filteredTrafficSources).map(key => {
          const { disabled, name } = filteredTrafficSources[key];
          return (
            <Menu.Item
              key={key}
              disabled={disabled}
              data-test={`${key}MenuItem`}
            >
              {name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }, [filteredTrafficSources]);

  const renderTrafficSourceOptions = () => {
    if (
      selectedTrafficSource === 'googleDisplay' ||
      selectedTrafficSource === 'googleSearch'
    ) {
      return (
        <>
          {trafficSourcesOptions[selectedTrafficSource].map(
            trafficSourceOption => (
              <Form.Item style={{ margin: 0 }} key={trafficSourceOption.label}>
                {getFieldDecorator(trafficSourceOption.value, {
                  initialValue: false,
                })(
                  <Checkbox data-test={trafficSourceOption.value}>
                    {trafficSourceOption.label}
                  </Checkbox>,
                )}
              </Form.Item>
            ),
          )}
        </>
      );
    }

    return null;
  };

  const renderPublishForm = () => {
    const passedProps = {
      publishCampaign,
      campaign,
      renderKeywords,
      getFieldDecorator,
      getFieldValue,
      setFieldsValue,
      generatedURL,
      uploadFileAction: uploadFile,
      resetUpload,
      selectedTrafficSource,
      campaignKeywords,
      keywordsLoading,
    };

    const { headlines, descriptions } = campaignCreatives;

    const trafficSourcesComponents = {
      googleDisplay: (
        <GoogleDisplay
          getTrafficSourceAccounts={getTrafficSourceAccounts}
          getVerticals={getVerticals}
          trafficSourceAccountList={trafficSourceAccountList}
          getTrafficSourceAccountsLoading={getTrafficSourceAccountsLoading}
          verticalsList={verticalsList}
          getVerticalsLoading={getVerticalsLoading}
          uploadFilesData={uploadFilesData}
          {...passedProps}
        />
      ),
      bing: <Bing {...passedProps} />,
      propellerGA: <Propeller {...passedProps} />,
      propellerNotGA: <Propeller {...passedProps} />,
      googleSearch: (
        <GoogleSearch
          getTrafficSourceAccounts={getTrafficSourceAccounts}
          trafficSourceAccountList={trafficSourceAccountList}
          getTrafficSourceAccountsLoading={getTrafficSourceAccountsLoading}
          headlines={headlines}
          descriptions={descriptions}
          {...passedProps}
        />
      ),
      facebook: (
        <Facebook
          allFbAudiences={allFbAudiences}
          allPagesIds={allPagesIds}
          isAudianceListLoading={isAudianceListLoading}
          isPageLoading={isPageLoading}
          getAudienceIds={getAudienceIds}
          getPageIds={getPageIds}
          {...passedProps}
        />
      ),
      zpGA: <ZeroPark {...passedProps} />,
      zpNotGA: <ZeroPark {...passedProps} />,
    };

    if (selectedTrafficSource in trafficSourcesComponents) {
      return trafficSourcesComponents[selectedTrafficSource];
    }
  };

  useEffect(() => {
    if (isPublishCampaignDrawerVisible) {
      getFinalUrl(campaign.url);
    }
  }, [isPublishCampaignDrawerVisible]); // eslint-disable-line

  useEffect(() => {
    resetFields();
    resetState();
    setWeight(100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrafficSource]);

  useEffect(() => {
    if (campaign) generatePresignedUrl(campaign._id);
  }, [campaign, generatePresignedUrl]);

  useEffect(() => {
    const kampaniaLink = campaignLinks?.kampania;
    if (kampaniaLink) {
      getKeywords(kampaniaLink);
    }

    return () => {
      resetKeywords();
    };
  }, [campaignLinks]); // eslint-disable-line

  useEffect(() => {
    if (isPublishCampaignSuccess && Object.keys(publishedCampaign).length) {
      setPublishCampaignDrawerVisible(false);
      showMessage('success', 'Campaign Published Successfully!');
      resetState();
      resetCurrentState();
      return () => {
        resetState();
        resetCurrentState();
      };
    }
  }, [
    isPublishCampaignSuccess,
    publishedCampaign,
    resetState,
    setPublishCampaignDrawerVisible,
  ]);

  useEffect(() => {
    if (campaignCreatives?.banners?.length) {
      handleDownloadOldFile(campaignCreatives?.banners);
    }

    return () => setUploadFilesData({});
  }, [campaignCreatives?.banners]); // eslint-disable-line

  const { finalUrl, redirectionOccured } = finalUrlData;

  return (
    <Drawer
      title="Publish Campaign"
      placement="right"
      {...(isMob
        ? { width: MOB_WIDTH_FOR_DRAWER }
        : { width: MOB_SCREEN_WIDTH })}
      onClose={() => {
        setPublishCampaignDrawerVisible(false);
        resetState();
        resetCurrentState();
      }}
      visible={isPublishCampaignDrawerVisible}
      destroyOnClose
    >
      <PublishCampaignCardsStyle>
        <FormContainerStyle>
          {redirectionOccured && finalUrl && (
            <Alert
              style={{ marginBottom: 24 }}
              description={
                <>
                  <p>A redirection occurs to this campaign url.</p>
                  <p>
                    Final url: <b>{finalUrl}</b>
                  </p>
                </>
              }
              type="warning"
            />
          )}
          <Form onSubmit={handlePublishCampaignSubmit}>
            <Card title="Campaign Info" size="small">
              <dl>
                <dt>Name</dt>
                <dd>{campaign?.name}</dd>

                <dt>Url</dt>
                <dd>{campaign?.url}</dd>

                <dt>Countries</dt>
                <dd>{formatList(campaign?.countries)}</dd>

                <dt>Devices</dt>
                <dd>{formatList(campaign?.devices)}</dd>
              </dl>
            </Card>

            <Card title="Traffic Source" size="small">
              <Dropdown overlay={renderSourcesMenu} style={{ width: '100%' }}>
                <Button
                  style={{
                    width: '100%',
                    ...flexBetweenCenter,
                  }}
                  data-test="selectTrafficSourceButton"
                >
                  {filteredTrafficSources[selectedTrafficSource]?.name ||
                    'Select a traffic source'}{' '}
                  <DownOutlined />
                </Button>
              </Dropdown>

              {selectedTrafficSource ? (
                <WeightSlider
                  campaign={campaign}
                  weight={weight}
                  setWeight={setWeight}
                  selectedTrafficSource={selectedTrafficSource}
                  setFieldsValue={setFieldsValue}
                  getFieldDecorator={getFieldDecorator}
                />
              ) : null}

              <div
                style={{
                  marginTop: '1rem',
                }}
              >
                {renderTrafficSourceOptions()}
              </div>
            </Card>

            {renderPublishForm()}

            {isPublishCampaignSuccess && publishedCampaign ? null : (
              <Alert
                style={{ marginBottom: 24 }}
                description={publishCampaignErrorMessage}
                type="error"
                closable
              />
            )}
            <Button
              type="primary"
              htmlType="submit"
              loading={publishCampaignLoading}
              data-test="publishButton"
            >
              Publish Campaign
            </Button>
          </Form>
        </FormContainerStyle>
      </PublishCampaignCardsStyle>
    </Drawer>
  );
}

PublishCampaign.propTypes = {
  campaign: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.string),
    _id: PropTypes.number,
  }),
};

PublishCampaign.defaultProps = {
  campaign: {
    countries: [{}],
    _id: '',
  },
};

export default connect(Form.create()(PublishCampaign), Module);
