import React, { useEffect, useState } from 'react';
import PageLayout from 'components/PageLayout';
import usersColumns from './usersColumns';
import { EyeOutlined } from '@ant-design/icons';
import { PageHeader, Table, Card, Input, Tooltip, Modal } from 'antd';
import useMedia from 'use-media';
import { MOB_SCREEN_WIDTH } from 'utils/constants';
import ViewAsUser from 'components/ViewAsUser';
import { SearchHeaderContainerStyle } from 'styles/styledComponents';
import { connect } from 'speedux';
import Module from './module';
import theme from 'styles/themes';

const { Search } = Input;
const OFFSET = 5;
const { gray } = theme.colors;
const iconStyles = {
  fontSize: '2.4rem',
  margin: '0 auto',
  display: 'block',
  cursor: 'pointer',
};
const Users = ({
  actions: { getUsers },
  state: { getAllUsersLoading, usersList, usersCount, token, userData },
}) => {
  const isFixedRight = useMedia({ maxWidth: 1358 });
  const isMob = useMedia({ maxWidth: MOB_SCREEN_WIDTH });
  const [searchPartners, setSearchPartners] = useState('');
  const [viewAsUserModal, setViewAsUserModal] = useState(false);
  const [userName, setUserNameToImpersonate] = useState('');
  const [impersonatedId, setImpersonaterId] = useState('');
  const [pagination, setPagination] = useState({
    limit: OFFSET,
    offset: 0,
  });

  const paginationConfig = {
    size: 'small',
    total: +usersCount,
    pageSize: OFFSET,
    onChange: pageNumber =>
      setPagination({ limit: OFFSET, offset: OFFSET * (pageNumber - 1) }),
  };

  useEffect(() => {
    getUsers({ ...pagination, search: searchPartners });
  }, [getUsers, pagination, searchPartners]);

  return (
    <PageLayout
      pageHeader={<PageHeader title="Users Dashboard" />}
      pageBody={
        <div {...(isMob ? {} : { style: { maxWidth: 'calc(100vw - 140px)' } })}>
          <Modal
            visible={viewAsUserModal}
            footer={null}
            closeIcon={<></>}
            width={'80%'}
          >
            <ViewAsUser
              viewAsUserModal={viewAsUserModal}
              setViewAsUserModal={setViewAsUserModal}
              impersonatedId={impersonatedId}
              userName={userName}
            />
          </Modal>
          <Card>
            <SearchHeaderContainerStyle>
              <div>
                <Search
                  placeholder="Search for users"
                  onSearch={setSearchPartners}
                  enterButton
                  style={{ marginBottom: 15 }}
                />
              </div>
            </SearchHeaderContainerStyle>
            <Table
              loading={getAllUsersLoading}
              rowKey="_id"
              columns={[
                ...usersColumns.data,
                {
                  title: 'Actions',
                  ...(isFixedRight ? { fixed: 'right' } : {}),
                  key: 'quickButtons',
                  render: ({ _id, first_name }) => (
                    <Tooltip>
                      <EyeOutlined
                        style={{
                          ...iconStyles,
                          color: gray,
                        }}
                        onClick={() => {
                          setViewAsUserModal(true);
                          setImpersonaterId(_id);
                          setUserNameToImpersonate(first_name);
                        }}
                      />
                    </Tooltip>
                  ),
                },
              ]}
              dataSource={usersList}
              pagination={paginationConfig}
              scroll={{ x: true }}
            />
          </Card>
        </div>
      }
    />
  );
};

export default connect(Users, Module);
