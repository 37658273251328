import React, { useEffect, useState } from 'react';
import { Input, Table, Card, Drawer, message } from 'antd';
import PageHeader from 'components/PageHeader';
import partnersColumns from './partnersColums';
import { connect } from 'speedux';
import Module from './module';
import { Link } from 'react-router-dom';
import { pathAllCampaigns } from 'routes/routes';
import useMedia from 'use-media';
import { MOB_SCREEN_WIDTH, MOB_WIDTH_FOR_DRAWER } from 'utils/constants';
import AddPartner from './addPartner';
import PageLayout from 'components/PageLayout';
import { PartnersListStyle } from './styles';
import { SearchHeaderContainerStyle } from 'styles/styledComponents';

const { Search } = Input;
const OFFSET = 5;
const Partners = ({
  state: {
    getAllPartnersLoading,
    partnersList,
    partnersCount,
    createPartnerLoading,
    submitted,
    generatedURL,
    partnerCreated,
  },
  actions: {
    getPartners,
    createPartner,
    generatePresignedUrl,
    uploadFile,
    resetPartnerCreated,
  },
}) => {
  const [searchPartners, setSearchPartners] = useState('');
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [pagination, setPagination] = useState({
    limit: OFFSET,
    offset: 0,
  });
  const paginationConfig = {
    size: 'small',
    total: +partnersCount,
    pageSize: OFFSET,
    onChange: pageNumber =>
      setPagination({ limit: OFFSET, offset: OFFSET * (pageNumber - 1) }),
  };

  const isFixedRight = useMedia({ maxWidth: 1358 });
  const isMob = useMedia({ maxWidth: MOB_SCREEN_WIDTH });

  useEffect(() => {
    getPartners({ ...pagination, search: searchPartners });
  }, [getPartners, pagination, searchPartners]);

  useEffect(() => {
    if (partnerCreated) {
      message.success('Partner created successfully.');
      setDrawerIsVisible(false);
    }

    return () => resetPartnerCreated();
  }, [partnerCreated]); //eslint-disable-line

  return (
    <PartnersListStyle>
      <PageLayout
        pageHeader={
          <PageHeader
            title="PARTNERS"
            btnText="create partner"
            btnOnChange={() => setDrawerIsVisible(!drawerIsVisible)}
            btnTestId={'addPartnerBtn'}
          >
            <Drawer
              title="ADD NEW PARTNER"
              placement="right"
              {...(isMob
                ? { width: MOB_WIDTH_FOR_DRAWER }
                : { width: MOB_SCREEN_WIDTH })}
              onClose={() => {
                setDrawerIsVisible(false);
              }}
              visible={drawerIsVisible}
              destroyOnClose
            >
              <AddPartner
                actions={{ createPartner, generatePresignedUrl, uploadFile }}
                state={{ createPartnerLoading, submitted, generatedURL }}
                setDrawerIsVisible={setDrawerIsVisible}
              />
            </Drawer>
          </PageHeader>
        }
        pageBody={
          <div
            {...(isMob ? {} : { style: { maxWidth: 'calc(100vw - 140px)' } })}
          >
            <Card>
              <SearchHeaderContainerStyle>
                <div>
                  <Search
                    placeholder="Search for partners"
                    onSearch={setSearchPartners}
                    enterButton
                  />
                </div>
              </SearchHeaderContainerStyle>
              <Table
                loading={getAllPartnersLoading}
                rowKey="_id"
                columns={[
                  ...partnersColumns.data,
                  {
                    title: 'Actions',
                    ...(isFixedRight ? { fixed: 'right' } : {}),
                    key: 'quickButtons',
                    render: data => (
                      <Link
                        to={`${pathAllCampaigns}?state=ALL&accountId=${data?.user_role?.account}&deliveryVisitsDuration=All`}
                      >
                        View Campaigns
                      </Link>
                    ),
                  },
                ]}
                pagination={paginationConfig}
                dataSource={partnersList}
                scroll={{ x: true }}
              />
            </Card>
          </div>
        }
      />
    </PartnersListStyle>
  );
};

export default connect(Partners, Module);
