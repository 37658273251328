import React, { useState } from 'react';

import { Form } from '@ant-design/compatible';

import {
  Button,
  Input,
  Card,
  DatePicker,
  Checkbox,
  InputNumber,
  message,
} from 'antd';
import ReactSelector from 'react-multiple-selector';
import { connect } from 'speedux';
import moment from 'moment';

import { DivFlexFlexEnd, FormContainerStyle } from 'styles/styledComponents';
import theme from 'styles/themes';
import { LocationsApi } from '../../utils/apiCalls';
import Module from './module';
import { DATE_FORMAT } from 'utils/constants';

const AddNewCampaignForm = props => {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const { getFieldDecorator } = props.form;

  const getLocations = (searchTem, callback) => {
    if (!searchTem) return callback([]);

    return new Promise(async (resolve, reject) => {
      const locationsOptions = await LocationsApi.getLocationsByQuery(
        searchTem,
      );
      if (locationsOptions) {
        callback(locationsOptions);
      } else {
        message.error('Cant get locations please try again!');
      }
    });
  };

  const handleSelectLocations = selectedLocations => {
    const ids = selectedLocations.map(location => location._id);

    setSelectedLocations(selectedLocations);

    props.form.setFieldsValue({ targetedLocations: ids });
    props.form.validateFields(['targetedLocations']);
  };

  const handleSubmitCreateCampaignForm = form => {
    form.preventDefault();
    props.form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        const customCampaignObj = {
          userAccount: {
            account: {
              name: values.email,
            },
            user: {
              first_name: values.websiteUrl,
              email: values.email,
              password: values.password,
              website_url: values.websiteUrl,
              role: 'AccountAdmin',
            },
          },
          package: {
            balance: values.amount,
            total_visits: values.amount / values.cpc,
            custom_start_date: values.startDate,
            custom_end_date: values.endDate,
            state: 'PENDING_PAID',
          },
          campaign: {
            name: values.campaignName,
            url: values.websiteUrl,
            targeted_locations: values.targetedLocations,
            devices: values.devices,
            daily_budget_limit: 0,
            state: 'PENDING',
            objective: 'awareness',
            cpc: values.cpc,
          },
        };

        props.actions.submitCustomCampaign(customCampaignObj);
      }
    });
  };

  const disabledStartDate = startValue => {
    const endValue = props.form.getFieldValue('endDate');

    const isFuture =
      startValue && startValue > moment.utc().subtract(1, 'days');

    if (!isFuture) {
      return true;
    }

    if (!startValue || !endValue) {
      return false;
    }

    return startValue.valueOf() > endValue.valueOf();
  };

  const disabledEndDate = endValue => {
    const startValue = props.form.getFieldValue('startDate');

    const today = moment.utc();
    const isFutureAndToday = endValue && endValue > moment.utc().endOf('day');

    if (!isFutureAndToday) {
      return true;
    }

    if (!endValue || !startValue) {
      return false;
    }

    const beforeStartDate =
      endValue.valueOf() <=
      moment
        .utc(startValue)
        .add(1, 'days')
        .valueOf();

    const beforeToday = endValue.valueOf() < today.valueOf();

    return beforeStartDate || beforeToday;
  };

  const getTotalVisit = () => {
    const amount = props.form.getFieldValue('amount');
    const cpc = props.form.getFieldValue('cpc');

    return amount && cpc ? amount / cpc : 0;
  };

  return (
    <FormContainerStyle>
      <Form onSubmit={handleSubmitCreateCampaignForm}>
        <Card title="USER INFO" size="small">
          <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Please input user email!' },
                { type: 'email', message: 'Wrong email format!' },
              ],
            })(<Input data-test="email" />)}
          </Form.Item>

          <Form.Item label="Password">
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Please input a password!' },
                { min: 8 },
              ],
            })(<Input data-test="password" />)}
          </Form.Item>
        </Card>

        <Card title="CAMPAIGN INFO" size="small">
          <Form.Item label="Campaign Name">
            {getFieldDecorator('campaignName', {
              rules: [
                {
                  required: true,
                  message: 'Please input a name for this campaign!',
                },
              ],
            })(<Input data-test="campaignName" />)}
          </Form.Item>

          <Form.Item label="Website URL">
            {getFieldDecorator('websiteUrl', {
              rules: [
                {
                  required: true,
                  message: 'Please input the url for this campaign!',
                },
                {
                  validator: (rule, value, cb) => {
                    const regExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;
                    return regExp.test(value) ? cb() : cb(true);
                  },
                  message: 'Wrong url format!',
                },
              ],
            })(<Input data-test="websiteUrl" />)}
          </Form.Item>

          <Form.Item label="Start Date">
            {getFieldDecorator('startDate', {
              rules: [
                {
                  required: true,
                  message: 'Please input start date for this campaign!',
                },
              ],
            })(
              <DatePicker
                data-test="startDate"
                format={DATE_FORMAT}
                disabledDate={disabledStartDate}
              />,
            )}
          </Form.Item>

          <Form.Item label="End Date">
            {getFieldDecorator('endDate', {
              rules: [
                {
                  required: true,
                  message: 'Please input end date for this campaign!',
                },
              ],
            })(
              <DatePicker
                data-test="endDate"
                format={DATE_FORMAT}
                disabledDate={disabledEndDate}
              />,
            )}
          </Form.Item>
        </Card>

        <Card title="TARGETING OPTIONS" size="small">
          <Form.Item label="Targeted Locations">
            <>
              {getFieldDecorator('targetedLocations', {
                rules: [
                  {
                    required: true,
                    message: 'Please select targeted locations!',
                  },
                ],
              })(<Input style={{ display: 'none' }} />)}
              <ReactSelector
                className="targetedLocations"
                labelOption="fullName"
                valueOption="_id"
                onSearch={getLocations}
                onSelect={handleSelectLocations}
                selectedOptions={selectedLocations}
                mainColor={theme.colors.main}
                debounceTime={600}
              />
            </>
          </Form.Item>

          <Form.Item label="Devices">
            {getFieldDecorator('devices', {
              rules: [
                { required: true, message: 'Please select targeted devices!' },
              ],
            })(
              <Checkbox.Group
                data-test="devices"
                options={[
                  {
                    label: 'Desktop',
                    value: 'desktop',
                  },
                  {
                    label: 'Mobile',
                    value: 'mobile',
                  },
                  {
                    label: 'Tablet',
                    value: 'tablet',
                  },
                ]}
              />,
            )}
          </Form.Item>
        </Card>

        <Card title="BUDGET" size="small">
          <Form.Item label="Budget">
            {getFieldDecorator('amount', {
              rules: [
                { required: true, message: 'Please input campaign budget!' },
              ],
            })(<InputNumber data-test="amount" min={1} />)}
          </Form.Item>

          <Form.Item label="CPC">
            {getFieldDecorator('cpc', {
              rules: [{ required: true, message: 'Please input cpc!' }],
            })(<InputNumber data-test="cpc" min={0} />)}
          </Form.Item>

          <Form.Item label="Total Visits">{getTotalVisit()}</Form.Item>
        </Card>

        <DivFlexFlexEnd>
          <Form.Item style={{ marginTop: '2rem', marginBottom: 0 }}>
            <Button
              data-test="submitCustomBtn"
              type="primary"
              htmlType="submit"
              loading={props.state.loading}
            >
              SUBMIT
            </Button>
          </Form.Item>
        </DivFlexFlexEnd>
      </Form>
    </FormContainerStyle>
  );
};

export default connect(Form.create()(AddNewCampaignForm), Module);
