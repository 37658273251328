import { connect } from 'speedux';
import { FileTextOutlined } from '@ant-design/icons';
import { Card, Select, Spin, Timeline } from 'antd';
import React, { useEffect, useState } from 'react';

import { ContainerStyle, HeaderContainerStyle, LogDateStyle } from './styles';
import { eventsMapper, eventTypes } from './helpers';
import Module from './module';

const { Option } = Select;

const History = ({
  campaignId,
  campaignName,
  state: { historyLogs, workflowRuns, getLogsLoading, getWorkflowRunsLoading },
  actions: { getCampaignLogs, getCampaignWorkflowRuns },
}) => {
  const [logs, setLogs] = useState([]);
  const [filter, setFilter] = useState('ALL');

  useEffect(() => {
    getCampaignLogs(campaignId);
    getCampaignWorkflowRuns(campaignId);
    setFilter('ALL');
  }, [campaignId, getCampaignLogs, getCampaignWorkflowRuns]);

  useEffect(() => {
    setLogs(eventsMapper({ historyLogs, workflowRuns, filter }));
  }, [filter, historyLogs, workflowRuns]);

  const loading = getLogsLoading || getWorkflowRunsLoading;

  return (
    <ContainerStyle>
      <HeaderContainerStyle>
        <h3>{campaignName}'s History</h3>
        <Select
          labelInValue
          value={{ key: filter }}
          onChange={item => setFilter(item.value)}
          style={{ minWidth: '25rem' }}
        >
          {eventTypes.map(typeKey => (
            <Option
              value={typeKey}
              key={typeKey}
              style={{ textTransform: 'capitalize' }}
            >
              {typeKey?.replace(/_/g, ' ')?.toLowerCase()}
            </Option>
          ))}
        </Select>
      </HeaderContainerStyle>

      <Spin spinning={loading} size="large">
        <Card
          title="Logs Timeline"
          size="small"
          style={{ marginTop: '2rem' }}
          bodyStyle={{ padding: '0.5rem' }}
        >
          <Timeline mode="left">
            {logs.map(log => (
              <Timeline.Item
                key={log.id}
                label={<LogDateStyle>{log.date}</LogDateStyle>}
                dot={log.icon}
                color={log.color}
              >
                <b style={{ textTransform: 'capitalize' }}>{log.title}</b>
              </Timeline.Item>
            ))}

            {!logs.length && !loading && (
              <h3 style={{ textAlign: 'center' }}>
                <b>
                  No logs <FileTextOutlined />
                </b>
              </h3>
            )}
          </Timeline>
        </Card>
      </Spin>
    </ContainerStyle>
  );
};

export default connect(History, Module);
