import {
  CardDefaultDivStyle,
  DivFlexSpaceBetween,
} from 'styles/styledComponents';
import { TABLET_SCREEN_WIDTH, MOB_SCREEN_WIDTH } from 'utils/constants';
import styled from 'styled-components';

export const ContainerStyle = styled(CardDefaultDivStyle)`
  & .ant-timeline {
    margin-top: 2rem;
  }
  & .ant-timeline.ant-timeline-label {
    & .ant-timeline-item-label {
      width: calc(30% - 12px);
    }

    & .ant-timeline-item-head,
    .ant-timeline-item-tail {
      left: 30%;
    }

    & .ant-timeline-item-left .ant-timeline-item-content {
      left: calc(30% - 4px);
      width: calc(70% - 14px);

      @media (max-width: ${MOB_SCREEN_WIDTH}px) {
        font-size: 1.5rem;
      }
    }
  }

  & .ant-select-selection-item {
    text-transform: capitalize;
  }
`;

export const HeaderContainerStyle = styled(DivFlexSpaceBetween)`
  @media (max-width: ${TABLET_SCREEN_WIDTH}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LogDateStyle = styled.p`
  font-weight: bold;

  @media (max-width: ${MOB_SCREEN_WIDTH}px) {
    font-size: 1.3rem;
    margin-top: 5%;
  }
`;
