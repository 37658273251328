import React, { useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import { Select, Spin } from 'antd';
import Validation from 'utils/Validation';

const { Option } = Select;
function AccountId({
  getFieldDecorator,
  getTrafficSourceAccounts,
  trafficSourceAccountList,
  getTrafficSourceAccountsLoading,
}) {
  useEffect(() => {
    if (!trafficSourceAccountList.length) {
      getTrafficSourceAccounts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Form.Item label="Account Id">
      {getFieldDecorator('account_id', {
        rules: [Validation.required],
      })(
        <Select
          showSearch
          loading={getTrafficSourceAccountsLoading}
          placeholder="Select an Account"
          optionFilterProp="children"
          notFoundContent={
            getTrafficSourceAccountsLoading ? <Spin size="small" /> : null
          }
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          data-test="accountIdSelector"
        >
          {trafficSourceAccountList?.length > 0
            ? trafficSourceAccountList.map((itm, indx) => (
                <Option
                  value={itm.account_id}
                  key={itm.account_id}
                  {...(indx === trafficSourceAccountList.length - 1
                    ? { 'data-test': 'accountSelected' }
                    : {})}
                >
                  {itm.account_name}
                </Option>
              ))
            : null}
        </Select>,
      )}
    </Form.Item>
  );
}

export default AccountId;
