import { Collapse } from 'antd';
import React from 'react';

import {
  CollapseContainerStyle,
  CollapsePanelHeaderStyle,
} from 'pages/CampaignDetails/styles';

import CampaignActionsPanel from './CampaignActionsPanel';
import IntegrationsActionsPanel from './IntegrationsActionsPanel';

const { Panel } = Collapse;

const Actions = ({
  campaign,
  revokeGaIntegration,
  revokeFbIntegration,
  revokeGaLoading,
  revokeFbLoading,
  onEditCampaign,
  fieldsToEdit,
  editCampaignLoading,
}) => {
  return (
    <CollapseContainerStyle>
      <Collapse defaultActiveKey={['1']} expandIconPosition="right" accordion>
        <Panel
          header={
            <CollapsePanelHeaderStyle>
              Campaign Actions
            </CollapsePanelHeaderStyle>
          }
          key="1"
        >
          <CampaignActionsPanel
            campaign={campaign}
            onEditCampaign={onEditCampaign}
            fieldsToEdit={fieldsToEdit}
            editCampaignLoading={editCampaignLoading}
          />
        </Panel>
        <Panel
          header={
            <CollapsePanelHeaderStyle>
              Integration Actions
            </CollapsePanelHeaderStyle>
          }
          key="2"
        >
          <IntegrationsActionsPanel
            campaign={campaign}
            revokeGa={revokeGaIntegration}
            revokeFb={revokeFbIntegration}
            revokeGaLoading={revokeGaLoading}
            revokeFbLoading={revokeFbLoading}
          />
        </Panel>
      </Collapse>
    </CollapseContainerStyle>
  );
};

export default Actions;
