import { Icon as LegacyIcon } from '@ant-design/compatible';
import React, { useEffect, useState } from 'react';

import {
  initialColumns,
  getTrafficSourceName,
  getBaseTrafficSourceName,
} from './tableHelpers';
import CustomizedTable from 'components/CustomizedTable';

import { DivFlexSpaceBetween } from 'styles/styledComponents';
import EditableField from '../../../../components/EditableField';
import { InputNumber } from 'antd';

const WfRunsTable = ({
  campaign,
  campaignWorkflowRuns,
  tsCampaignsReport,
  accountTransactions,
  cloneCampaign,
  cloneCampaignLoading,
  editTSCampaignWeight,
  editWeightLoading,
  doneEditingWeight,
}) => {
  const [selectedWfRun, setSelectedWfRun] = useState('');
  const [weight, setWeight] = useState(null);
  const [editMode, setEditMode] = useState(null);

  useEffect(() => {
    if (doneEditingWeight) {
      setEditMode(false);
    }

    return () => setEditMode(null);
  }, [doneEditingWeight]);

  const getTableColumns = () => {
    const shownColumns = [];

    const workFlowRuns = getTableData();
    const modifiedInitialColumns = [
      ...initialColumns.map(col => {
        if (col.key === 'workflow_name') {
          return {
            title: 'Traffic Source',
            dataIndex: 'workflow_name',
            key: 'workflow_name',
            width: 250,
            render: (workFlowName, { wf_run_id: workFlowRunId }) => {
              const baseTrafficSource = getBaseTrafficSourceName(workFlowName);
              const cloneCampaignForWfRunLoading =
                cloneCampaignLoading && selectedWfRun === workFlowRunId;
              return (
                <DivFlexSpaceBetween>
                  {getTrafficSourceName(workFlowName)}
                  {baseTrafficSource === 'google' && (
                    <LegacyIcon
                      style={{ marginLeft: 8, fontSize: 17 }}
                      type={cloneCampaignForWfRunLoading ? 'loading' : 'copy'}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedWfRun(workFlowRunId);
                        cloneCampaign(workFlowRunId, baseTrafficSource);
                      }}
                    />
                  )}
                </DivFlexSpaceBetween>
              );
            },
          };
        }
        if (col.key === 'assigned_budget_percent') {
          return {
            title: 'ASSIGNED BUDGET PERCENTAGE',
            dataIndex: 'weight',
            key: 'assigned_budget_percent',
            width: 200,
            render: (value, { wf_run_id: workFlowRunId }) => {
              const newWeight = weight || value;

              return (
                <EditableField
                  editMode={editMode}
                  value={`${value}%`}
                  disableSaveButton={!weight || weight === value}
                  onSave={() => {
                    editTSCampaignWeight(newWeight, workFlowRunId);
                    setWeight(null);
                  }}
                  onCancel={() => {
                    setWeight(null);
                  }}
                  loadingEdit={editWeightLoading}
                >
                  <InputNumber
                    placeholder="Total Visits"
                    defaultValue={value}
                    onChange={e => setWeight(e)}
                    data-test={`total_visits-input`}
                    max={100}
                  />
                </EditableField>
              );
            },
          };
        }

        return col;
      }),
    ];

    modifiedInitialColumns.forEach(column => {
      if (
        workFlowRuns.some(wfRun =>
          Object.keys(wfRun).includes(column.dataIndex),
        )
      ) {
        shownColumns.push(column);
      }
    });

    return shownColumns;
  };

  const getNativeTsCampaignId = (ts, tsCampaignObjId) => {
    const id = tsCampaignsReport?.[ts]?.find(
      campaign =>
        campaign?.ts_campaign_id?.toString() === tsCampaignObjId?.toString(),
    )?.[`${ts}_campaign_id`];

    return id;
  };

  const getTableData = () => {
    const {
      package: { balance, abm },
    } = campaign;
    const maxAllowedBudget = Number((balance * abm).toFixed(2));

    const modifiedWorkFlowRunsTableData = [];
    campaignWorkflowRuns.forEach(wfRun => {
      const tsCampaignObjId = wfRun?.ts_campaign_id;
      const consumedBudgetForThiswfRun = accumulateChargeTransactions(
        tsCampaignObjId?.toString(),
      );
      const tsCampaigns = tsCampaignsReport?.ts_campaigns;
      const tsCampaignInfo = tsCampaigns.find(
        tsCampaign => tsCampaign.id === tsCampaignObjId,
      );

      const totalCost = Number(tsCampaignInfo?.last_consumed_cost).toFixed(2);
      const totalCostDisplayed = totalCost === 'NaN' ? 'N/A' : totalCost;

      // Get native traffic source campaign id
      let nativeTsCampaignId = 'N/A';

      const tsName = tsCampaignInfo?.traffic_source?.name?.toLowerCase();
      if (tsName?.includes('google')) {
        nativeTsCampaignId = getNativeTsCampaignId('google', tsCampaignObjId);
      } else {
        nativeTsCampaignId =
          tsCampaignInfo?.delivery_id || tsCampaignInfo?.testing_id;
      }

      modifiedWorkFlowRunsTableData.push({
        ...wfRun,
        weight: Number(wfRun.weight) * 100,
        assigned_budget: Number(wfRun.weight) * maxAllowedBudget,
        consumed_budget: consumedBudgetForThiswfRun,
        key: wfRun.wf_run_id,
        actual_cost: totalCostDisplayed,
        campaign_id: nativeTsCampaignId,
      });
    });

    return modifiedWorkFlowRunsTableData;
  };

  const accumulateChargeTransactions = wfRunCampaignId => {
    const { _id: campaignId } = campaign;

    const instascalerCampaignId = campaignId?.toString();

    return accountTransactions.reduce((acc, transaction) => {
      const transactionInstascalerCampaignId = transaction?.instascaler_campaign_id?.toString();

      const transactionTSCampaignId = transaction?.traffic_source_campaign_id?.toString();

      const instascalerCampaignRelatedTransaction =
        transactionInstascalerCampaignId === instascalerCampaignId;
      const tsCampaignRelatedTransaction =
        transactionTSCampaignId === wfRunCampaignId;

      if (
        transaction.type === 'CHARGE' &&
        instascalerCampaignRelatedTransaction
      ) {
        if (
          (wfRunCampaignId && tsCampaignRelatedTransaction) ||
          !wfRunCampaignId
        ) {
          return acc + (transaction.amount || 0);
        }
      }
      return acc;
    }, 0);
  };

  return (
    <CustomizedTable
      hasMinWidth
      columns={getTableColumns()}
      data={getTableData()}
    />
  );
};

export default WfRunsTable;
