/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';

import {
  ColumnWidthOutlined,
  DeleteOutlined,
  FilePptOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  PoundOutlined,
  SendOutlined,
} from '@ant-design/icons';
import useMedia from 'use-media';
import { Form as OldForm } from '@ant-design/compatible';

import {
  Button,
  Result,
  Spin,
  Tabs,
  Tooltip,
  Popconfirm,
  DatePicker,
  Modal,
  InputNumber,
  Input,
  Drawer,
  Form,
} from 'antd';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  campaignsStatusMap,
  LIMIT,
  getSearchParams,
  DATE_FORMAT,
  MOB_WIDTH_FOR_DRAWER,
  MOB_SCREEN_WIDTH,
} from 'utils/constants';
import { connect } from 'speedux';
import Module from 'pages/CampaignDetails/module';
import PublishCampaign from 'components/PublishCampaign';

import {
  ContainerStyle,
  CardHeaderStyle,
  CampaignNameStyle,
  CampaignStateStyle,
  TabHeaderStyle,
  CampaignDetailsHeaderNameStateStyle,
  CampaignDetailsActionIcons,
  actionIconStyles,
  NotificationFormContainerStyle,
} from './styles';
import Actions from './Actions';
import Analytics from './Analytics';
import CampaignInformation from './CampaignInformation';
import CampaignMaterial from './CampaignMaterial';
import Optimization from './Optimization';
import Publishing from './Publishing';
import { showMessage } from 'utils/helpers';
import { useLocation } from 'react-router-dom';
import theme from 'styles/themes';
import moment from 'moment';
import { FormContainerStyle } from 'styles/styledComponents';

const { TabPane } = Tabs;
const { blue, green, orange, red } = theme.colors;

const CampaignDetails = ({
  actions: {
    getCampaign,
    editCampaign,
    archiveCampaign,
    editPackage,
    editUserEmail,
    getCampaignCreatives,
    getCampaignRules,
    getCampaignGaGoals,
    getCampaignWorkflowRuns,
    getTsCampaigns,
    getCampaignGAViews,
    getCampaignScripts,
    getKampaniaData,
    getCampaignIntegrations,
    getAccountTransactions,
    cloneCampaign,
    patchCampaignGAView,
    editCampaignOpentagScripts,
    generateIntegrationToken,
    revokeGaIntegration,
    revokeFbIntegration,
    resetState,
    resetCampaignCreatives,
    extendCampaign,
    editTSCampaignWeight,
    resetEditWeight,
    resetCampaign,
    sendNotification,
  },
  state: {
    campaign,
    campaignLinks,
    fetchCampaignLoading,
    fetchCampaignError,

    campaignCreatives,
    campaignRules,
    campaignWorkflowRuns,
    tsCampaigns,
    campaignLocations,
    opentagScripts,
    gaGoals,
    gaViews,
    accountTransactions,
    fieldsToEdit,

    editLoading,
    fetchKampaniaDataLoading,
    fetchCampaignIntegrationsLoading,
    editCampaignOpenTagLoading,
    fetchCampaignGAViewsLoading,
    fetchCampaignScriptsLoading,
    fetchCampaignGoalsLoading,
    patchCampaignGAViewLoading,
    fetchCampaignWorkflowRunsLoading,
    fetchTsCampaignsLoading,
    fetchCampaignRulesLoading,
    cloneCampaignLoading,

    gaIntegrationToken,
    generateGaIntegrationTokenLoading,

    fbIntegrationToken,
    generateFbIntegrationTokenLoading,

    revokeGaLoading,
    revokeFbLoading,
    archiveCampaignLoading,
    isCampaignArchived,
    isCampaignUpdated,
    extendCampaignLoading,
    sucessExtendCampaign,
    extendCampaignError,
    editWeightLoading,
    doneEditingWeight,
    sendNotificationLoading,
  },
  globalState: { fetchAllCampaignsLoading },
  campaignId,
  selectedTabKey,
  selectSectionTabKey,
  subSectionTabSelected,
  selectSubSectionFromTab,
  getAllCampaigns,
}) => {
  const campaignDetailsRef = useRef(null);
  const [additionalBalanceValue, setAdditionalBalance] = useState('');
  const [extendDate, setExtendDate] = useState('');
  const location = useLocation();
  const [isExtendModal, setExtendModal] = useState(false);
  const [
    sendNotificationDrawerVisible,
    setSendNotificationDrawerVisible,
  ] = useState(false);
  const params = getSearchParams(location.search);
  const [
    isPublishCampaignDrawerVisible,
    setPublishCampaignDrawerVisible,
  ] = useState(false);
  const [editMode, setEditMode] = useState(null);

  const [notificationForm] = Form.useForm();

  const isMob = useMedia({ maxWidth: MOB_SCREEN_WIDTH });

  useEffect(() => {
    if (campaignId) {
      getCampaign(campaignId);
    }

    return () => {
      resetCampaign();
      resetState();
    };
  }, [campaignId]); //eslint-disable-line

  useEffect(() => {
    if (campaign?.state && campaign?.state !== 'ARCHIVED' && campaignId) {
      getCampaignCreatives(campaignId);
    }

    return () => resetCampaignCreatives();
  }, [campaignId, campaign?.state]); //eslint-disable-line

  useDeepCompareEffect(() => {
    if (campaign?.package?.account) {
      getAccountTransactions();
    }
  }, [campaign]); //eslint-disable-line

  useEffect(() => {
    if (isCampaignArchived) {
      showMessage('success', 'Campaign Archived Successfully!');
    }

    if (isCampaignArchived || isCampaignUpdated) {
      getAllCampaigns({
        campaignParams: {
          state: params.get('state'),
          search: params.get('search'),
          accountId: params.get('accountId'),
        },
        limit: LIMIT,
        page: 1,
      });

      setEditMode(false);
    }

    return () => {
      setEditMode(null);
      resetState();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCampaignArchived, isCampaignUpdated]);

  useEffect(() => {
    if (!isExtendModal) {
      setAdditionalBalance('');
      setExtendDate('');
    }
  }, [isExtendModal]);

  useEffect(() => {
    if (doneEditingWeight) {
      showMessage('warning', `Weight will be edited soon!`);
      resetEditWeight();
    }
  });

  useEffect(() => {
    if (sucessExtendCampaign && !extendCampaignLoading) {
      setExtendModal(false);
      showMessage('success', 'Campaign Extended Successfully!');
      getAllCampaigns({
        campaignParams: {
          state: params.get('state'),
          search: params.get('search'),
          accountId: params.get('accountId'),
        },
        limit: LIMIT,
        page: 1,
      });
    }

    if (extendCampaignError) showMessage('error', extendCampaignError);

    return () => {
      resetState();
    };
  }, [sucessExtendCampaign, extendCampaignLoading, extendCampaignError]);

  const renderActionIcons = () => {
    const iconsToRender = [];
    const accountId = campaign?.package?.account;

    const allowedIdsForSendingMsgs = process?.env?.REACT_APP_SEND_MSG_IDS?.split(
      ',',
    );

    const isAllowedToSendMsgId = allowedIdsForSendingMsgs?.includes(
      `${accountId}`,
    );

    if (
      campaignState === 'EXPIRED' &&
      campaign?.delivery_utm_source !== 'instascaler'
    ) {
      iconsToRender.push(
        <ColumnWidthOutlined
          style={{
            ...actionIconStyles,
            color: green,
          }}
          onClick={() => {
            setExtendModal(true);
          }}
        />,
      );
    }
    const campaignStatesToShowPublishIcon = [
      'RUNNING',
      'PENDING_PAID',
      'PAUSED',
    ];

    if (campaignStatesToShowPublishIcon.includes(campaignState)) {
      iconsToRender.push(
        <Tooltip title="Publish Campaign" key={`${campaignState}-publish`}>
          <FilePptOutlined
            onClick={() => setPublishCampaignDrawerVisible(true)}
            style={{ color: blue, ...actionIconStyles }}
          />
        </Tooltip>,
      );
    }

    if (isAllowedToSendMsgId) {
      const onFinish = values => {
        const body = {
          issue_category: values.notificationCategory,
          issue_message: values.notificationBody,
        };

        sendNotification(campaignId, body, () => {
          setSendNotificationDrawerVisible(false);
        });
      };

      iconsToRender.push(
        <>
          <Tooltip title="Send Notification">
            <SendOutlined
              style={{ color: green, ...actionIconStyles }}
              onClick={() => {
                setSendNotificationDrawerVisible(true);
              }}
            />
          </Tooltip>
          <Drawer
            title="Send Notification"
            {...(isMob
              ? { width: MOB_WIDTH_FOR_DRAWER }
              : { width: MOB_SCREEN_WIDTH })}
            onClose={() => {
              setSendNotificationDrawerVisible(false);
            }}
            destroyOnClose
            visible={sendNotificationDrawerVisible}
            afterVisibleChange={visible => {
              if (!visible) {
                notificationForm.resetFields();
              }
            }}
            footer={
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <Button
                  onClick={() => {
                    notificationForm.submit();
                  }}
                  type="primary"
                  loading={sendNotificationLoading}
                >
                  Send
                </Button>
              </div>
            }
          >
            <NotificationFormContainerStyle>
              <Form
                name="sendNotificationForm"
                form={notificationForm}
                onFinish={onFinish}
              >
                <Form.Item
                  label="Notification Category"
                  name="notificationCategory"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter notification category',
                    },
                  ]}
                >
                  <Input placeholder="Category" />
                </Form.Item>
                <Form.Item
                  label="Notification Body"
                  name="notificationBody"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter notification body',
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Notification body"
                    autoSize={{ minRows: 10 }}
                  />
                </Form.Item>
              </Form>
            </NotificationFormContainerStyle>
          </Drawer>
        </>,
      );
    }

    if (campaignState === 'PAUSED') {
      iconsToRender.push(
        <Popconfirm
          title="Are you sure Resume this campaign?"
          onConfirm={() => {
            editCampaign({ state: 'RUNNING' });
          }}
          key={`${campaignState}-play`}
          okText="Yes"
          cancelText="No"
          placement="bottomLeft"
        >
          <Tooltip title="Resume">
            <PlayCircleOutlined style={{ color: green, ...actionIconStyles }} />
          </Tooltip>
        </Popconfirm>,
      );
    } else if (campaignState === 'RUNNING') {
      iconsToRender.push(
        <Popconfirm
          title="Are you sure Pause this campaign?"
          onConfirm={() => {
            editCampaign({ state: 'PAUSED' });
          }}
          key={`${campaignState}-pause`}
          okText="Yes"
          cancelText="No"
          placement="bottomLeft"
        >
          <Tooltip title="Pause">
            <PauseCircleOutlined
              style={{ color: orange, ...actionIconStyles }}
            />
          </Tooltip>
        </Popconfirm>,
      );
    }

    if (campaignState !== 'ARCHIVED') {
      iconsToRender.push(
        <Popconfirm
          title="Are you sure Archive this campaign?"
          onConfirm={() => {
            archiveCampaign(campaignId);
          }}
          key={`${campaignState}-archive`}
          okText="Yes"
          cancelText="No"
          placement="bottomLeft"
        >
          <Tooltip title="Archive">
            <DeleteOutlined style={{ color: red, ...actionIconStyles }} />
          </Tooltip>
        </Popconfirm>,
      );
    }

    return iconsToRender.map(i => i);
  };

  const onEditSave = updatedField => {
    const [updatedFieldKey] = Object.keys(updatedField);

    const packageFields = [
      'package_type',
      'amount',
      'bonus',
      'abm',
      'total_visits',
      'custom_start_date',
      'custom_end_date',
      'last_renew_date',
      'payment_method',
      'balance',
      'auto_renew',
    ];

    if (packageFields.includes(updatedFieldKey)) {
      editPackage(updatedField);
    } else if (updatedFieldKey === 'email') {
      editUserEmail(updatedField);
    } else {
      editCampaign(updatedField);
    }
  };

  const onEditOpentagScripts = newScripts => {
    editCampaignOpentagScripts(campaignId, newScripts);
  };

  const onGenerateIntegrationToken = platform => {
    generateIntegrationToken(campaignId, platform);
  };

  const onGetCampaignGAViews = () => {
    getCampaignGAViews(campaignId);
  };

  const onGetGaGoals = () => {
    getCampaignGaGoals(campaignId);
  };

  const onPatchCampaignGAView = ({ viewId, webPropertyId }) => {
    patchCampaignGAView({ campaignId, viewId, webPropertyId });
  };

  const onRevokeGaIntegration = () => {
    revokeGaIntegration(campaignId);
  };

  const onRevokeFbIntegration = () => {
    revokeFbIntegration(campaignId);
  };

  const onGetKampaniaData = () => {
    getKampaniaData(campaignId);
  };

  const onGetCampaignIntegrations = () => {
    getCampaignIntegrations(campaignId);
  };

  const onGetCampaignWorkflowRuns = () => {
    getCampaignWorkflowRuns(campaignId);
  };

  const onGetTsCampaigns = () => {
    getTsCampaigns(campaign?.kampania_id);
  };

  const onGetCampaignRules = () => {
    getCampaignRules(campaignId);
  };

  const loadingCampaign = fetchCampaignLoading || fetchAllCampaignsLoading;

  const campaignName = campaign?.name?.toLowerCase() || 'Campaign Name';
  const campaignState = campaign.state || 'Campaign State';

  const { title: stateTitle, color: stateColor } = campaignsStatusMap[
    campaignState
  ];

  const toggleDisableAllChildButtons = (parentComponent, isDisable) => {
    const parentComponentButtons = parentComponent?.getElementsByTagName(
      'button',
    );
    if (parentComponentButtons) {
      for (const child of parentComponentButtons) {
        if (child.className.includes('ant-switch')) {
          child.className += ' ant-switch-disabled';
        }
        child.disabled = isDisable;
      }
    }
  };

  useEffect(() => {
    if (campaign?.kampania_id || campaign?.kampania_id === 0)
      onGetTsCampaigns();
  }, [campaign._id]);

  useEffect(() => {
    const campaignDetailsRefInEffect = campaignDetailsRef.current;
    if (campaign.state === 'ARCHIVED') {
      toggleDisableAllChildButtons(campaignDetailsRefInEffect, true);
    }

    return () => {
      toggleDisableAllChildButtons(campaignDetailsRefInEffect, false);
      selectSubSectionFromTab('1');
    };
  }, [
    campaign.state,
    selectedTabKey,
    subSectionTabSelected,
    loadingCampaign,
    fetchCampaignRulesLoading,
    fetchKampaniaDataLoading,
    selectSubSectionFromTab,
  ]);

  if (fetchCampaignError && !loadingCampaign) {
    return (
      <ContainerStyle centeralize>
        <Result
          status={
            fetchCampaignError?.errorCode?.toString()?.includes('4')
              ? '404'
              : '500'
          }
          title={fetchCampaignError?.errorCode}
          subTitle="An error happened while fetching campaign"
          extra={
            <Button
              type="primary"
              onClick={() => campaignId && getCampaign(campaignId)}
            >
              Try again
            </Button>
          }
        />
      </ContainerStyle>
    );
  }

  if (!campaignId && campaign?._id && !loadingCampaign) {
    return (
      <ContainerStyle centeralize>
        <Result
          status="404"
          title="404"
          subTitle="Looks like no campaign is chosen"
        />
      </ContainerStyle>
    );
  }

  return (
    <ContainerStyle selectedTabKey={selectedTabKey} ref={campaignDetailsRef}>
      <Modal
        title={'Extend This Campaign'}
        visible={isExtendModal}
        onOk={() => {
          extendCampaign({
            campaignId,
            balance: additionalBalanceValue,
            endDate: moment.utc(extendDate).format(DATE_FORMAT),
          });
        }}
        confirmLoading={extendCampaignLoading}
        onCancel={() => {
          setExtendModal(false);
          setAdditionalBalance('');
          setExtendDate('');
        }}
      >
        <FormContainerStyle>
          <OldForm.Item label="Additional Balance">
            <InputNumber
              value={additionalBalanceValue}
              formatter={value =>
                value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value && value.replace(/\$\s?|(,*)/g, '')}
              prefix={<PoundOutlined />}
              onChange={setAdditionalBalance}
              placeholder="Balance"
            />
          </OldForm.Item>

          <OldForm.Item label="End Date">
            <DatePicker
              value={extendDate}
              format={DATE_FORMAT}
              onChange={setExtendDate}
              placeholder="End Date"
              disabledDate={current =>
                current && current < moment.utc().endOf('day')
              }
            />
          </OldForm.Item>
        </FormContainerStyle>
      </Modal>
      <Spin
        spinning={loadingCampaign || archiveCampaignLoading || editLoading}
        size="large"
      >
        <CardHeaderStyle>
          <CampaignDetailsHeaderNameStateStyle>
            <CampaignNameStyle data-test="campaign-name">
              {campaignName}
            </CampaignNameStyle>
            {stateTitle && (
              <CampaignStateStyle stateColor={stateColor}>
                {stateTitle}
              </CampaignStateStyle>
            )}
          </CampaignDetailsHeaderNameStateStyle>
          {!loadingCampaign && (
            <CampaignDetailsActionIcons>
              {renderActionIcons()}
            </CampaignDetailsActionIcons>
          )}
        </CardHeaderStyle>

        <Tabs onChange={selectSectionTabKey} activeKey={selectedTabKey}>
          <TabPane
            tab={
              <TabHeaderStyle data-test="campaign-information-tab">
                Campaign Information
              </TabHeaderStyle>
            }
            key="1"
          >
            <CampaignInformation
              subSectionTabSelected={subSectionTabSelected}
              selectSubSectionFromTab={selectSubSectionFromTab}
              campaign={campaign}
              onEditSave={onEditSave}
              isCampaignUpdated={isCampaignUpdated}
              fieldsToEdit={fieldsToEdit}
              editCampaignLoading={editLoading}
              campaignLocations={campaignLocations}
              accountTransactions={accountTransactions}
              tsCampaignsReport={tsCampaigns}
              fetchTsCampaignsLoading={fetchTsCampaignsLoading}
              editMode={editMode}
            />
          </TabPane>
          <TabPane
            tab={
              <TabHeaderStyle data-test="campaign-material-tab">
                Campaign Material
              </TabHeaderStyle>
            }
            key="2"
          >
            <CampaignMaterial
              campaignCreatives={campaignCreatives}
              campaignName={campaign.name}
            />
          </TabPane>
          <TabPane
            tab={
              <TabHeaderStyle data-test="optimization-tab">
                Optimization
              </TabHeaderStyle>
            }
            key="3"
          >
            <Optimization
              campaign={campaign}
              onEditSave={onEditSave}
              editMode={editMode}
              isCampaignUpdated={isCampaignUpdated}
              getCampaignRules={onGetCampaignRules}
              campaignRules={campaignRules}
              fetchCampaignRulesLoading={fetchCampaignRulesLoading}
              fieldsToEdit={fieldsToEdit}
              editCampaignLoading={editLoading}
              onGetCampaignIntegrations={onGetCampaignIntegrations}
              getKampaniaData={onGetKampaniaData}
              fetchKampaniaDataLoading={fetchKampaniaDataLoading}
            />
          </TabPane>
          <TabPane
            tab={
              <TabHeaderStyle data-test="publishing-tab">
                Publishing
              </TabHeaderStyle>
            }
            key="4"
          >
            <Publishing
              campaign={campaign}
              getCampaignWorkflowRuns={onGetCampaignWorkflowRuns}
              campaignWorkflowRuns={campaignWorkflowRuns}
              tsCampaignsReport={tsCampaigns}
              fetchCampaignWorkflowRunsLoading={
                fetchCampaignWorkflowRunsLoading
              }
              fetchTsCampaignsLoading={fetchTsCampaignsLoading}
              accountTransactions={accountTransactions}
              cloneCampaign={cloneCampaign}
              cloneCampaignLoading={cloneCampaignLoading}
              editTSCampaignWeight={editTSCampaignWeight}
              editWeightLoading={editWeightLoading}
              doneEditingWeight={doneEditingWeight}
              setPublishCampaignDrawerVisible={setPublishCampaignDrawerVisible}
            />
          </TabPane>
          <TabPane
            tab={
              <TabHeaderStyle data-test="analytics-tab">
                Analytics
              </TabHeaderStyle>
            }
            key="5"
          >
            <Analytics
              subSectionTabSelected={subSectionTabSelected}
              selectSubSectionFromTab={selectSubSectionFromTab}
              campaign={campaign}
              opentagScripts={opentagScripts}
              getGaGoals={onGetGaGoals}
              getCampaignScripts={getCampaignScripts}
              fetchCampaignScriptsLoading={fetchCampaignScriptsLoading}
              gaGoals={gaGoals}
              gaViews={gaViews}
              editOpentagScripts={onEditOpentagScripts}
              editCampaignOpenTagLoading={editCampaignOpenTagLoading}
              getCampaignGAViews={onGetCampaignGAViews}
              fetchCampaignGAViewsLoading={fetchCampaignGAViewsLoading}
              patchCampaignGAView={onPatchCampaignGAView}
              fetchCampaignGoalsLoading={fetchCampaignGoalsLoading}
              patchCampaignGAViewLoading={patchCampaignGAViewLoading}
              generateIntegrationToken={onGenerateIntegrationToken}
              generateGaIntegrationTokenLoading={
                generateGaIntegrationTokenLoading
              }
              generateFbIntegrationTokenLoading={
                generateFbIntegrationTokenLoading
              }
              gaIntegrationToken={gaIntegrationToken}
              fbIntegrationToken={fbIntegrationToken}
              getCampaignIntegrations={onGetCampaignIntegrations}
              fetchCampaignIntegrationsLoading={
                fetchCampaignIntegrationsLoading
              }
              selectedTabKey={selectedTabKey}
            />
          </TabPane>
          <TabPane
            tab={
              <TabHeaderStyle data-test="actions-tab">Actions</TabHeaderStyle>
            }
            key="6"
          >
            <Actions
              campaign={campaign}
              revokeGaIntegration={onRevokeGaIntegration}
              revokeFbIntegration={onRevokeFbIntegration}
              revokeGaLoading={revokeGaLoading}
              revokeFbLoading={revokeFbLoading}
              onEditCampaign={onEditSave}
              isCampaignUpdated={isCampaignUpdated}
              fieldsToEdit={fieldsToEdit}
              editCampaignLoading={editLoading}
            />
          </TabPane>
        </Tabs>
      </Spin>
      <PublishCampaign
        campaign={campaign}
        campaignCreatives={campaignCreatives}
        campaignLinks={campaignLinks}
        setPublishCampaignDrawerVisible={setPublishCampaignDrawerVisible}
        isPublishCampaignDrawerVisible={isPublishCampaignDrawerVisible}
      />
    </ContainerStyle>
  );
};

export default connect(CampaignDetails, Module);
