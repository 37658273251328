import axios from 'axios';
import Cookies from 'universal-cookie';
import { COOKIES_AUTHORIZATION, REFRESH_TOKEN } from 'utils/constants';

const cookies = new Cookies();

export const request = async ({
  url,
  method = 'get',
  body,
  token = cookies.get(COOKIES_AUTHORIZATION),
  isFullResponse,
}) => {
  try {
    const apiCall = await axios({
      method: method.toLowerCase(),
      url: url,
      ...(['post', 'patch', 'put'].includes(method) ? { data: body } : {}),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS,PUT,DELETE',
        Authorization: token,
      },
    });

    return isFullResponse ? apiCall : apiCall.data;
  } catch (e) {
    if (e?.response?.status === 401) {
      try {
        const refreshToken = cookies.get(REFRESH_TOKEN);
        const {
          data: { access_token: newAccessToken },
        } = await axios.post(
          `${process.env.REACT_APP_API_URL}/v3/public/users/refreshToken`,
          {
            refresh_token: refreshToken,
          },
        );

        if (newAccessToken) {
          cookies.set(COOKIES_AUTHORIZATION, newAccessToken);
          return request({
            url,
            method,
            body,
            token,
            isFullResponse,
          });
        } else {
          cookies.remove(COOKIES_AUTHORIZATION);
          cookies.remove(REFRESH_TOKEN);
        }
      } catch (err) {
        cookies.remove(COOKIES_AUTHORIZATION);
        cookies.remove(REFRESH_TOKEN);
      }
    }

    const error = {
      errorCode: e.response.status,
      errorMsg:
        e.response.statusText ||
        e?.response?.data?.errorStackTrace?.error?.error || //https://babeljs.io/docs/en/babel-plugin-proposal-optional-chaining
        e.response.data.error ||
        e.response.data.message,
    };

    throw new Error(JSON.stringify(error));
  }
};
