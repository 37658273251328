import React, { useRef, useState } from 'react';
import { Tooltip } from 'antd';
import {
  FacebookOutlined,
  GoogleOutlined,
  EyeOutlined,
  BarChartOutlined,
  FieldTimeOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import theme from 'styles/themes';

import {
  ActionsBoxStyle,
  ActionSquareStyle,
  FullIntegrationBoxesStyle,
} from './styles';
import { useOutsideClick } from 'utils/helpers';

const { gray, blue, green, red, main } = theme.colors;
const iconStyles = {
  fontSize: '2.4rem',
  margin: '0 0.4rem',
};
const integrationsColors = {
  isFacebookIntegrated: blue,
  isGoogleIntegrated: green,
  isMatomoIntegrated: red,
};

const renderIntegrationColor = (source, isIntegrated) =>
  isIntegrated ? integrationsColors[source] : gray;

const ActionSquare = ({
  url,
  triggerGaIntegrationView,
  deliveryUtmSource,
  isGoogleIntegrated,
  isFacebookIntegrated,
  isMatomoIntegrated,
  onClickCampaign,
  onViewReport,
  onViewHistory,
  onViewCostReport,
  id: campaignId,
  createdAt,
  setViewAsUserModal,
  onViewAsUserClick,
  onSelectSpecificSection,
  name,
  balance,
}) => {
  const actionsBoxRef = useRef(null);
  useOutsideClick(actionsBoxRef, () => isFullMenu && toggleFullMenu(false));

  const [isFullMenu, toggleFullMenu] = useState(false);

  const renderActionBox = () => {
    return (
      <ActionSquareStyle>
        <span
          style={{
            backgroundColor: renderIntegrationColor(
              'isGoogleIntegrated',
              isGoogleIntegrated,
            ),
          }}
        />
        <span
          style={{
            backgroundColor: renderIntegrationColor(
              'isFacebookIntegrated',
              isFacebookIntegrated,
            ),
          }}
        />
        <span
          style={{
            backgroundColor: renderIntegrationColor(
              'isMatomoIntegrated',
              isMatomoIntegrated,
            ),
          }}
        />
        <span style={{ backgroundColor: gray }} />
      </ActionSquareStyle>
    );
  };

  const renderIntegrationsStates = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <Tooltip
          title={
            isGoogleIntegrated
              ? 'Google is integrated'
              : 'Google is not integrated'
          }
        >
          <GoogleOutlined
            style={{
              ...iconStyles,
              color: renderIntegrationColor(
                'isGoogleIntegrated',
                isGoogleIntegrated,
              ),
            }}
            {...(isGoogleIntegrated
              ? {
                  onClick: () => {
                    triggerGaIntegrationView({
                      id: campaignId,
                      name,
                      url,
                      deliveryUtmSource,
                    });
                  },
                }
              : {
                  onClick: () => {
                    onClickCampaign(campaignId);
                    onSelectSpecificSection('5', '2');
                  },
                })}
          />
        </Tooltip>

        <Tooltip
          title={
            isFacebookIntegrated
              ? 'Facebook is integrated'
              : 'Facebook is not integrated'
          }
        >
          <FacebookOutlined
            style={{
              ...iconStyles,
              color: renderIntegrationColor(
                'isFacebookIntegrated',
                isFacebookIntegrated,
              ),
            }}
          />
        </Tooltip>

        {deliveryUtmSource === 'instascaler' ? (
          <Tooltip title="View as user">
            <EyeOutlined
              style={{
                ...iconStyles,
                color: main,
              }}
              onClick={() => {
                setViewAsUserModal(true);
                onViewAsUserClick(campaignId, name);
              }}
            />
          </Tooltip>
        ) : null}

        <Tooltip title="Campaign report">
          <BarChartOutlined
            style={{
              ...iconStyles,
              color: main,
            }}
            onClick={() => {
              onViewReport(campaignId, name);
            }}
          />
        </Tooltip>

        <Tooltip title="Campaign History">
          <FieldTimeOutlined
            style={{
              ...iconStyles,
              color: gray,
            }}
            onClick={() => {
              onViewHistory(campaignId, name);
            }}
          />
        </Tooltip>
        <Tooltip title="Cost Report">
          <DollarOutlined
            style={{
              ...iconStyles,
              color: main,
            }}
            onClick={() => {
              onViewCostReport(campaignId, name, balance, createdAt);
            }}
          />
        </Tooltip>
      </div>
    );
  };
  return (
    <ActionsBoxStyle
      onClick={e => {
        e.stopPropagation();
        toggleFullMenu(!isFullMenu);
      }}
      ref={actionsBoxRef}
    >
      {isFullMenu ? (
        <FullIntegrationBoxesStyle>
          {renderIntegrationsStates()}
          {renderActionBox()}
        </FullIntegrationBoxesStyle>
      ) : (
        renderActionBox()
      )}
    </ActionsBoxStyle>
  );
};

export default ActionSquare;
