import { CampaignsApi } from 'utils/apiCalls';
import { handleApiError } from 'utils/helpers';

export default {
  name: 'Campaigns',
  state: {
    fetchPartnersLoading: false,
    partnersList: [],
    fetchPartnersError: null,
    fetchCampaignsLoading: false,
    fetchCampaignsError: null,
    isfetchCampaignsGetsError: false,
    campaignAdded: false,
  },
  actions: {
    *getAllPartners() {
      yield { fetchPartnersLoading: true };
      try {
        const data = yield CampaignsApi.getPartnerList().catch(e => e);
        handleApiError(data);
        const partnersList = data.map(partner => ({
          id: partner.user_role.account,
          username: partner.username,
        }));

        yield {
          fetchPartnersLoading: false,
          partnersList: partnersList,
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchPartnersLoading: false,
          fetchPartnersError: message,
        };
      }
    },

    *getAllCampaigns({ campaignParams, limit, page }) {
      yield {
        fetchCampaignsLoading: true,
      };
      try {
        let offset = 0;
        if (page) {
          offset = (page - 1) * limit;
        }

        const urlParams = {
          ...(campaignParams?.state === 'includeArchived'
            ? { includeArchived: true }
            : { state: campaignParams?.state }),
          accountId: campaignParams?.accountId,
          search: campaignParams?.search,
          limit: limit,
          offset: offset,
        };

        const campaignsResponse = yield CampaignsApi.getAllCampaigns(
          urlParams,
        ).catch(e => e);
        handleApiError(campaignsResponse);

        yield {
          fetchCampaignsLoading: false,
          campaignsCount: Number(campaignsResponse.headers['x-count']),
          current: page,
          filteredCampaigns: campaignsResponse.data,
        };
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          fetchCampaignsLoading: false,
          isfetchCampaignsGetsError: true,
          fetchCampaignsError: errorMsg,
        };
      }
    },

    resetCampaignAdded() {
      return {
        campaignAdded: false,
      };
    },
  },

  handlers: {
    '@@AddCustomCampaign/SUBMIT_CUSTOM_CAMPAIGN/COMPLETE': action => {
      return {
        campaignAdded: true,
      };
    },
  },
};
