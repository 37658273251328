import { request } from './apiAxios';
import axios from 'axios';
import { notificationsEvent } from './helpers';

const splitQueryParams = urlParams => {
  let queryString = '';
  Object.keys(urlParams).forEach(k => {
    if (urlParams[k] !== null && urlParams[k] !== undefined) {
      queryString += `${k}=${urlParams[k]}&`;
    }
  });

  return queryString;
};

export const AuthApi = {
  login(userCredential) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/public/users/signin`,
      method: 'post',
      body: userCredential,
    });
  },
  getUser(token) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/users/me`,
      method: 'get',
      token,
    });
  },
  userImpersonate(campaignId, token) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/users/impersonate`,
      method: 'post',
      body: campaignId,
      token,
    });
  },
};

export const CampaignsApi = {
  getCampaignKeywords(endpoint) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/${endpoint}`,
    });
  },

  getAllCampaigns(urlParams) {
    if (urlParams.state === 'ALL') delete urlParams.state;
    const URLParams = urlParams;

    Object.keys(urlParams).forEach(
      k => !urlParams[k] && urlParams[k] !== 0 && delete URLParams[k],
    );

    const params = new URLSearchParams(URLParams).toString();

    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/campaigns${
        params ? `/?${params}` : ''
      }`,
      method: 'get',
      isFullResponse: true,
    });
  },

  getReport(
    campaignId,
    startDate,
    endDate,
    groupByDay,
    cities,
    getCost = false,
  ) {
    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/v2/campaigns/report/${campaignId}?start_date=${startDate}&end_date=${endDate}${
        groupByDay ? '&group_by=day' : ''
      }${cities ? '&cities=true' : ''}&getCost=${getCost}&useReportingId=true`,
    });
  },

  getAssistedConversions(analyticsId, startDate, endDate) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/analytics/report/ga/${analyticsId}/mcf?start_date=${startDate}&end_date=${endDate}`,
    });
  },

  getDailyClicksAndVisitsReport({
    analyticsId,
    type,
    startDate,
    endDate,
    campaignId,
    devices,
    countries,
    utmSource,
  }) {
    if (type) {
      let params = '';
      if (type === 'visits')
        params = `${analyticsId}?type=${type}&startDate=${startDate}&endDate=${endDate}${
          devices ? `&devices=${devices}` : ''
        }${countries ? `&countries=${countries}` : ''}${
          utmSource ? `&source=${utmSource}` : '&source=instascaler'
        }`;
      else
        params = `${campaignId}?type=${type}&startDate=${startDate}&endDate=${endDate}&analyticsId=${analyticsId}`;
      return request({
        url: `${process.env.REACT_APP_API_URL}/analytics/reports/daily/${params}`,
      });
    }
  },

  getCostReport({ campaignId, analyticsId, startDate, endDate }) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/analytics/reports/dailycost/${campaignId}?startDate=${startDate}&endDate=${endDate}&analyticsId=${analyticsId}`,
    });
  },

  getCampaignResults(campaignId, startDate, endDate) {
    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/v3/campaigns/${campaignId}/reports?${
        startDate ? `&start_date=${startDate}` : ''
      }${endDate ? `&end_date=${endDate}` : ''}`,
    });
  },

  getCampaignsStateCount() {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/statesCount`,
    });
  },

  archiveCampaignById(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}`,
      method: 'delete',
    });
  },

  getCampaignCreatives(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}/creatives`,
    });
  },

  getCampaignRules(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}/rules`,
    });
  },

  getCampaignWorkflowRuns(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}/wf-runs`,
    });
  },

  getTsCampaigns(id) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/kampania/api/v3/icampaigns/${id}`,
    });
  },

  getCampaignOpentagScripts(encryptedId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/scripts/${encryptedId}`,
    });
  },

  addCustomCampaign(customCampaignObj) {
    const customCampaign = JSON.stringify(customCampaignObj);
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/custom`,
      method: 'post',
      body: customCampaign,
    });
  },

  getCampaignById(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/campaigns/${campaignId}?with_user=true`,
    });
  },

  getKampaniaData(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/campaigns/${campaignId}/kampania`,
    });
  },

  getIntegrations(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/campaigns/${campaignId}/reports/integrations`,
    });
  },

  editCampaign({ campaignId, updatedField, isInstascalerCampaign }) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}${
        isInstascalerCampaign ? '' : '?disable_intercom=true'
      }`,
      method: 'patch',
      body: updatedField,
    });
  },

  editCampaignOpentagScripts(campaignId, data, isInstascalerCampaign) {
    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/v2/campaigns/scripts/${campaignId}${
        isInstascalerCampaign ? '' : '?disable_intercom=true'
      }`,
      method: 'post',
      body: data,
    });
  },

  getPartnerList() {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/users/partners?with=balance&search=&`,
      method: 'get',
    });
  },

  getCampaignHistory(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/logs/api/events/?instascaler_id=${campaignId}&ordering=-created_at`,
      method: 'get',
    });
  },

  getCampaignNotifications(campaignId, eventName) {
    let eventsParam;

    if (eventName === 'ALL') {
      eventsParam = `event_name_in=${notificationsEvent.map((event, i) =>
        i - 1 === notificationsEvent.length ? event + ',' : event,
      )}`;
    } else {
      eventsParam = `event_name=${eventName}`;
    }

    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/logs/api/events/?ordering=-created_at&${eventsParam}&${
        campaignId ? 'instascaler_id=' + campaignId : ''
      }`,
      method: 'get',
    });
  },

  cloneCampaign(wfrunId, trafficSource) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${wfrunId}/${trafficSource}/clone/`,
      method: 'post',
      body: {},
    });
  },

  publishCampaign(campaignId, options) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/publish/${campaignId}`,
      method: 'post',
      body: options,
    });
  },
  getVerticals() {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/google/verticals`,
      method: 'get',
    });
  },
  getTrafficSourceAccounts() {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/accounts/traffic_source_account`,
      method: 'get',
    });
  },
  getPages(campaignId) {
    const url = `${process.env.REACT_APP_API_URL}/v2/pages/${campaignId}`;
    return request({ url });
  },
  getAudiences(trafficSource) {
    const url = `${process.env.REACT_APP_API_URL}/v2/audiences/${trafficSource}`;
    return request({ url });
  },
  extendCampaign({ campaignId, endDate, balance }) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/campaigns/${campaignId}/extension?end_date=${endDate}`,
      body: { balance },
      method: 'post',
    });
  },

  getFinalUrl(URL) {
    const url = `${process.env.REACT_APP_GET_REDIRECTS_URL}?url=${URL}`;
    return axios.get(url);
  },

  editTSCampaignWeight(body) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/kampania/api/v3/icampaigns/edit_traffic_source_weight/`,
      body: { ...body },
      method: 'post',
    });
  },
};

export const PackagesApi = {
  editPackage({ packageId, updatedField, isInstascalerCampaign }) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/packages/${packageId}${
        isInstascalerCampaign ? '' : '?disable_intercom=true'
      }`,
      method: 'patch',
      body: updatedField,
    });
  },
};

export const UsersApi = {
  getAccount(accountId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/accounts/${accountId}`,
    });
  },

  addPartner(partnerObj) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/accounts`,
      method: 'post',
      body: JSON.stringify(partnerObj),
    });
  },

  getUsers(urlParams) {
    const queryString = splitQueryParams(urlParams);
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/users/search?${queryString}`,
      isFullResponse: true,
    });
  },

  getPartners(urlParams) {
    const queryString = splitQueryParams(urlParams);
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/users/partners?with=balance&${queryString}`,
      isFullResponse: true,
    });
  },

  getCampaignUserEmails(endpoint) {
    return request({
      url: `${process.env.REACT_APP_API_URL}${endpoint}`,
    });
  },
};

export const LocationsApi = {
  getLocationsByQuery(query) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/locations?search=${encodeURI(
        query,
      )}`,
    });
  },
};

export const IntegrationsApi = {
  generateIntegrationToken(campaignId, isInstascalerCampaign) {
    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/v2/campaigns/${campaignId}/googleAnalytics/externalUser/token${
        !isInstascalerCampaign ? '?requester=metadsp' : ''
      }`,
    });
  },
  revokeGa(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/revokeGA/${campaignId}`,
      method: 'delete',
    });
  },
  revokeFb(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}/facebookAds`,
      method: 'delete',
    });
  },
};

export const GAApi = {
  getCampaignGoals(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/report/${campaignId}?filter=${process.env.REACT_APP_GA_DURATION}&useReportingId=true`,
    });
  },
  getCampaignGAViews(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}/gaViews?keepGA=true`,
    });
  },
  patchGAView({ campaignId, viewId, webPropertyId }) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}/analytics`,
      method: 'patch',
      body: {
        ga: {
          profile_id: viewId,
          web_property_id: webPropertyId,
        },
      },
    });
  },

  getUTMSources({ analyticsId, startDate, endDate }) {
    const params =
      startDate && endDate ? `start_date=${startDate}&end_date=${endDate}` : ``;
    return request({
      url: `${process.env.REACT_APP_API_URL}/analytics/ga/sources/${analyticsId}?${params}`,
      method: 'GET',
    });
  },
  getUTMKeywordText({ keywordId, accountId, adgroupId }) {
    return request({
      url: `
      ${process.env.REACT_APP_API_URL}/v2/reports/utmKeywordText?keyword_id=${keywordId}&account_id=${accountId}&adgroup_id=${adgroupId}
    `,
      method: 'GET',
    });
  },
  getGAReport({ analyticsId, startDate, endDate, utmFilter, keywords }) {
    let params =
      startDate && endDate ? `startDate=${startDate}&endDate=${endDate}` : '';

    params += utmFilter ? `&source=${utmFilter}` : '&source=instascaler';
    params += keywords ? '&keywords=true' : '';

    return request({
      url: `${process.env.REACT_APP_API_URL}/analytics/report/ga/${analyticsId}?${params}`,
      method: 'GET',
    });
  },
};

export const PaymentApi = {
  getAccountTransactions(accountId, limit = 1000) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/payment/account/${accountId}/transactions?limit=${limit}`,
    });
  },

  getPaymanCost({ accountId, campaignId, startDate, endDate }) {
    let params = '';
    if (startDate) {
      params += `&transaction_date__gte=${startDate}`;
    }
    if (endDate) {
      params += `&transaction_date__lte=${endDate}`;
    }

    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/payman-external/api/v1/account-transaction/group_transaction?${
        accountId ? `account_id=${accountId}&` : ''
      }instascaler_campaign_id=${campaignId}&group_daily_charges=true${params}`,
    });
  },
};

export const UploadApi = {
  generatePresignedUrl(campaignId, urlParams = {}) {
    const queryString = splitQueryParams(urlParams);

    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/upload/generatepresignedurl/${campaignId}/method/putObject?${queryString}`,
      method: 'get',
    });
  },
};

export const NotificationsApi = {
  sendNotification(campaignId, body) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/notify/issue?campaign_id=${campaignId}`,
      method: 'post',
      body: {
        ...body,
      },
    });
  },
};
