import React from 'react';
import { formatDate } from 'utils/helpers';

export default {
  data: [
    {
      title: 'Id',
      dataIndex: '_id',
      key: 'id',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Website URL',
      dataIndex: 'website_url',
      key: 'website_url',
    },
    {
      title: 'Create  Date',
      dataIndex: 'created_at',
      render: date => <span>{formatDate(date)}</span>,
      key: 'created_at',
    },
  ],
};
