import theme from 'styles/themes';

const { gray, red, orange, blue, green } = theme.colors;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';
export const AUTH_EXPIRATION_PERIOD = 2592000;
export const DEBOUNCE_TIMEOUT = 1000;

export const COOKIES_AUTHORIZATION = 'Authorization';
export const REFRESH_TOKEN = 'refresh_token';
export const COOKIES_NOTIFICATION_LAST_CHK = 'notLastChk';
export const LIMIT = 20;

export const getSearchParams = searchPath => new URLSearchParams(searchPath);

export const MOB_SCREEN_WIDTH = 576;
export const TABLET_SCREEN_WIDTH = 768;
export const LAPTOP_SCREEN_WIDTH = 1280;

export const LAPTOP_SCREEN_HEIGHT = 850;

export const MOB_WIDTH_FOR_DRAWER = 375;
export const flexBetweenCenter = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
export const instascalerPackageTypes = ['MICRO', 'STARTER', 'SCALER', 'SUPER'];
export const campaignsStatusMap = new Proxy(
  {
    includeArchived: { title: 'All', color: gray },
    ALL: { title: 'All but archived', color: gray },
    RUNNING: { title: 'Running', color: green },
    PENDING: { title: 'Pending', color: orange },
    PENDING_PAID: { title: 'Pending Paid', color: orange },
    DRAFT: { title: 'Draft', color: blue },
    EXPIRED: { title: 'Expired', color: red },
    SUSPENDED: { title: 'Suspended', color: red },
    PAUSED: { title: 'Paused', color: orange },
    ARCHIVED: { title: 'Archived', color: red },
  },
  {
    get: (obj, prop) => {
      if (!(prop in obj)) return obj['ALL'];
      return obj[prop];
    },
  },
);

export const FULL_DATE_FORMAT = 'DD MMMM YYYY - HH:MM';
