import { MOB_SCREEN_WIDTH } from 'utils/constants';
import { createGlobalStyle } from 'styled-components';
import theme from './themes';

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;

    .ant-btn-primary{
      text-transform: uppercase;
    }

    .selectKeywordsDropdown {
      visibility: hidden;
    }

    .targetedLocations{
        width: 100%;
    }

    .ant-select-selection.ant-select-selection--multiple{
      max-height: 200px;
      overflow: auto;
    }

    dl {
      display: grid;
      grid-template-columns: max-content auto;
    }

    dt {
      grid-column-start: 1;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 400;

      &:after{
        content: ':'
      }
    }

    dd {
      grid-column-start: 2;
    }

    .ant-spin,
    .ant-spin-spinning{
      cursor: progress;
    }

    .ant-input-number {
      width: 100%;
    }

    .ant-layout-sider-zero-width-trigger{
      top: 1rem;
    }

    .ant-slider{
      width: 100%;
    }

    .ant-alert-description {
      text-transform: capitalize;
    }

    @media (max-width: ${MOB_SCREEN_WIDTH}px) {
      font-size: calc(14px * 62.5 / 100);

      dl {
        display: list-item;
      }

      .ant-calendar-range {
        width: 330px;
      }

      .ant-calendar-date-panel {
        display: flex;
        flex-direction: column;

        & .ant-calendar-range-part {
          width: 100%;
        }

        & .ant-calendar-range-right .ant-calendar-date-input-wrap {
          margin-left: 0;
        }
      }
    }

    .ant-table-thead {
      th {
        text-transform: uppercase;
      }
    }

    .cogni-report__large {
      min-width: inherit;
    }

    .cogni-report__card {
      padding: 20px 0;
    }

    .cogni-report__card h3 {
      margin: -20px 0 20px;
    }

    *::-webkit-scrollbar {
      width: 0.7rem;
      height: 0.7rem;
    }

    *::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }

    *::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.grayLight};
      outline: 1px solid ${theme.colors.grayLighter};
    }
  }
`;
