import { EditOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Button, Checkbox, message } from 'antd';
import React, { useState, useEffect } from 'react';
import ReactSelector from 'react-multiple-selector';
import Validation from 'utils/Validation';

import {
  RowContainerStyle,
  KeyContainerStyle,
  ValueContainerStyle,
} from 'pages/CampaignDetails/styles';
import EditableField from 'components/EditableField';
import { LocationsApi } from 'utils/apiCalls';
import theme from 'styles/themes';
import {
  EditButtonsContainerStyle,
  EditLocationsContainerStyle,
  LocationsContainerStyle,
  SingleLocationStyle,
} from 'pages/CampaignDetails/CampaignInformation/styles';

const CheckboxGroup = Checkbox.Group;

const TargetingOptionsPanel = ({
  campaign,
  onEditSave,
  fieldsToEdit,
  editCampaignLoading,
  campaignLocations,
  isCampaignUpdated,
  form,
  editMode,
}) => {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isEditingLocations, setIsEditingLocations] = useState(false);
  const [currentLocations, setCurrentLocations] = useState([]);
  const [editedValues, setEditedValues] = useState({
    devices: '',
  });

  useEffect(() => {
    setCurrentLocations(campaign.locations);
    setSelectedLocations(campaign.locations);
    setIsEditingLocations(false);
  }, [campaign.locations]);

  useEffect(() => {
    if (isCampaignUpdated) {
      setCurrentLocations(selectedLocations);
    }
  }, [isCampaignUpdated, selectedLocations]);

  const { devices } = campaign;

  const getLocations = (searchTem, callback) => {
    if (!searchTem) return callback([]);

    return new Promise(async (resolve, reject) => {
      const locationsOptions = await LocationsApi.getLocationsByQuery(
        searchTem,
      );
      if (locationsOptions) {
        callback(locationsOptions);
      } else {
        message.error('Cant get locations please try again!');
      }
    });
  };

  const onEditLocations = () => {
    const selectedLocationsIds = selectedLocations.map(
      location => location._id,
    );
    onEditSave({ targeted_locations: selectedLocationsIds });
    setIsEditingLocations(false);
  };

  const onCancelEditLocations = () => {
    setIsEditingLocations(false);
    setSelectedLocations(campaignLocations);
  };

  const renderSelectedLocations = () => (
    <LocationsContainerStyle>
      <div>
        {currentLocations?.map(l => (
          <SingleLocationStyle key={l.fullName}>
            {l.fullName || l.name}
          </SingleLocationStyle>
        ))}
      </div>
      {campaign.state !== 'ARCHIVED' && (
        <EditOutlined
          title="Click to Edit"
          style={{ fontSize: 18 }}
          onClick={() => setIsEditingLocations(true)}
        />
      )}
    </LocationsContainerStyle>
  );

  const isSaveLocationsDisabled =
    campaignLocations &&
    selectedLocations &&
    JSON.stringify(campaignLocations.map(l => l._id).sort()) ===
      JSON.stringify(selectedLocations.map(l => l._id).sort());

  const isEditLoading = fieldKey =>
    editCampaignLoading && fieldsToEdit.includes(fieldKey);

  const isSaveButtonDisabled = (name, value) =>
    !editedValues[name] || editedValues[name]?.toString() === value?.toString();

  const { getFieldDecorator, setFieldsValue } = form;

  const devicesOptions = [
    {
      label: 'Desktop',
      value: 'desktop',
    },
    {
      label: 'Mobile',
      value: 'mobile',
    },
    {
      label: 'Tablet',
      value: 'tablet',
    },
  ];

  return (
    <>
      <RowContainerStyle>
        <KeyContainerStyle>Devices</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            value={devices}
            disableSaveButton={isSaveButtonDisabled('devices', devices)}
            onSave={() => onEditSave({ devices: editedValues['devices'] })}
            onCancel={() => {
              setEditedValues({ ...editedValues, devices });
              setFieldsValue({ devices });
            }}
            loadingEdit={isEditLoading('devices')}
            editMode={editMode}
          >
            <Form.Item>
              {getFieldDecorator('devices', {
                initialValue: devices,
                rules: [Validation.required],
              })(
                <CheckboxGroup
                  options={devicesOptions}
                  onChange={values =>
                    setEditedValues({
                      ...editedValues,
                      devices: values,
                    })
                  }
                />,
              )}
            </Form.Item>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle lastRow>
        <KeyContainerStyle>Targeted Locations</KeyContainerStyle>
        <ValueContainerStyle>
          {isEditingLocations ? (
            <EditLocationsContainerStyle>
              <ReactSelector
                labelOption="fullName"
                valueOption="_id"
                onSearch={getLocations}
                onSelect={setSelectedLocations}
                selectedOptions={selectedLocations}
                mainColor={theme.colors.main}
                debounceTime={600}
              />
              <EditButtonsContainerStyle>
                <Button
                  onClick={onCancelEditLocations}
                  style={{ marginRight: '1rem' }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={onEditLocations}
                  loading={isEditLoading('targeted_locations')}
                  disabled={isSaveLocationsDisabled}
                >
                  Save
                </Button>
              </EditButtonsContainerStyle>
            </EditLocationsContainerStyle>
          ) : (
            renderSelectedLocations()
          )}
        </ValueContainerStyle>
      </RowContainerStyle>
    </>
  );
};

export default Form.create()(TargetingOptionsPanel);
