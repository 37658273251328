import React, { useEffect, useState } from 'react';
import { connect } from 'speedux';
import { Card, Dropdown, Input, Menu, PageHeader, Spin } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Module from './module';
import PageLayout from 'components/PageLayout';
import { notificationsEvent, getNotificationBody } from 'utils/helpers';
import {
  SearchContainerStyle,
  FiltersContainerStyle,
  NotificationsContainerStyle,
  NotificationItemStyle,
} from './styles';
import { pathAllCampaigns } from '../../routes/routes';
import { FULL_DATE_FORMAT } from '../../utils/constants';

const { Search } = Input;

const Notifications = ({
  actions: { getCampaignNotifications, resetNotifications },
  state: { notifications, getNotificationsLoading },
}) => {
  const [eventName, setEventName] = useState('ALL');
  const [campaignId, setCampaignId] = useState(null);

  useEffect(() => {
    getCampaignNotifications(campaignId, eventName);

    return () => {
      resetNotifications();
    };
  }, [eventName, campaignId]); //eslint-disable-line

  const renderEventFilter = () => (
    <Menu onClick={e => setEventName(e.key)} style={{ width: '28rem' }}>
      {['ALL', ...notificationsEvent].map(event => {
        return (
          <Menu.Item key={event} data-test={`event-${event}`}>
            {event}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const renderNotifications = () => {
    return notifications.map(notification => {
      const notificationBody = getNotificationBody(notification);

      return (
        <NotificationItemStyle
          key={notificationBody?.campaignId + notificationBody?.date}
          color={notificationBody?.color}
        >
          <div>
            <span>{notificationBody?.msg}</span>
            <span>
              {moment.utc(notificationBody?.date).format(FULL_DATE_FORMAT)}
            </span>
          </div>
          <Link
            to={`${pathAllCampaigns}?state=ALL&search=${notificationBody?.campaignId}`}
          >
            View Campaigns
          </Link>
        </NotificationItemStyle>
      );
    });
  };

  return (
    <PageLayout
      pageHeader={<PageHeader title="Notifications" />}
      pageBody={
        <Card
          bodyStyle={{
            maxHeight: '100%',
            overflowY: 'scroll',
          }}
        >
          <FiltersContainerStyle>
            <Dropdown.Button
              overlay={renderEventFilter}
              data-test="change-event"
              trigger={['click', 'hover']}
            >
              {eventName}
            </Dropdown.Button>
            <SearchContainerStyle>
              <Search
                placeholder="Campaign Id"
                onSearch={value => setCampaignId(+value)}
                type="number"
              />
            </SearchContainerStyle>
          </FiltersContainerStyle>
          <Spin spinning={getNotificationsLoading} size="large">
            <NotificationsContainerStyle>
              {renderNotifications()}
            </NotificationsContainerStyle>
          </Spin>
        </Card>
      }
    />
  );
};

export default connect(Notifications, Module);
