import { CampaignsApi, PaymentApi } from 'utils/apiCalls';
import {
  handleApiError,
  showMessage,
  capitalizeFirstLetter,
} from 'utils/helpers';
import moment from 'moment';

const dimensionIndex = {
  conversionGoalNumber: 1,
  assistedConversions: 2,
  lastInteractionConversions: 3,
};

const getDimensionCount = (dimension, primitiveValues, utmSource) =>
  primitiveValues.reduce(
    (acc, cur) =>
      utmSource === cur[0]
        ? Number(acc) + Number(cur[dimensionIndex[dimension]])
        : Number(acc),
    0,
  );

const getConversionsReport = (assistedConversionsReport, report, utmSource) => {
  const allPrimitiveValues = assistedConversionsReport?.rows;

  const goalNumbers = allPrimitiveValues
    ? [
        ...new Set(
          allPrimitiveValues
            .map(row =>
              row[0].primitiveValue === utmSource
                ? row[1].primitiveValue
                : undefined,
            )
            .filter(Boolean),
        ),
      ].sort()
    : [];

  const goalReportRowsPrimitiveValues = allPrimitiveValues
    ? allPrimitiveValues.map(row =>
        row.map(primitiveValue => Object.values(primitiveValue)[0]),
      )
    : [];

  const goalNumbersData = {};

  goalNumbers.forEach(goalNumber => {
    const goalNumberPrimitiveValues = goalReportRowsPrimitiveValues.filter(
      primitiveValues =>
        primitiveValues[dimensionIndex.conversionGoalNumber] === goalNumber,
    );

    Object.assign(goalNumbersData, {
      [goalNumber]: {
        assistedConversions: getDimensionCount(
          'assistedConversions',
          goalNumberPrimitiveValues,
          utmSource,
        ),
        lastInteractionConversions: getDimensionCount(
          'lastInteractionConversions',
          goalNumberPrimitiveValues,
          utmSource,
        ),
      },
    });
  });

  const goalsConversionsTotal = Object.keys(goalNumbersData)
    .map(key => goalNumbersData[key])
    .reduce(
      (acc, cur) => ({
        assistedConversions: acc.assistedConversions + cur.assistedConversions,
        lastInteractionConversions:
          acc.lastInteractionConversions + cur.lastInteractionConversions,
      }),
      {
        assistedConversions: 0,
        lastInteractionConversions: 0,
      },
    );

  const conversionsForAllGoals =
    report &&
    report.conversions &&
    Object.assign(
      {},
      ...report.conversions
        .map(itm =>
          Object.keys(goalNumbersData).includes(itm.name)
            ? {
                [itm.name]: itm.conversion * report.total_sessions,
              }
            : null,
        )
        .filter(Boolean),
    );

  return [goalsConversionsTotal, conversionsForAllGoals, goalNumbersData];
};

const getLineChartData = ({
  report,
  campaignReport,
  assistedConversions = 0,
  paymanCost,
  dailyCost,
  dailyVisits,
  dailyClicks,
}) => {
  const dailyReport = report?.daily;

  const reformattedPaymanCost = {};

  // eslint-disable-next-line no-unused-expressions
  paymanCost?.data?.forEach(({ total, transaction_date }) => {
    reformattedPaymanCost[transaction_date] =
      Number(total / 100) + (reformattedPaymanCost[transaction_date] || 0);
  });

  Object.keys(dailyReport).forEach(day => {
    dailyReport[day].totalVisits = dailyVisits?.daily?.[day]?.total_visits || 0;
    dailyReport[day].totalClicks = dailyClicks?.daily?.[day]?.total_clicks || 0;
    dailyReport[day].totalCost = dailyCost?.daily_cost?.[day]?.total_cost || 0;
  });

  const [totalClicksAll, totalVisitsAll, totalCostAll] = Object.keys(
    dailyReport,
  ).reduce(
    ([totalClicks, totalVisits, totalCost], cur) => {
      return [
        (totalClicks += Number(dailyReport[cur].totalClicks)),
        (totalVisits += Number(dailyReport[cur].totalVisits)),
        (totalCost += Number(dailyReport[cur].totalCost)),
      ];
    },
    [0, 0, 0],
  );

  const totalCost = Object.keys(reformattedPaymanCost).reduce(
    (acc, cur) => reformattedPaymanCost[cur] + acc,
    0,
  );

  campaignReport = {
    ...campaignReport,
    totalVisits: totalVisitsAll,
    totalCost: totalCostAll,
    totalClicks: totalClicksAll,
  };

  const lineChartData = [
    {
      id: 'visits',
      color: '#656da3',
      legend: {
        value: campaignReport?.totalVisits || 0,
        color: '#fff',
      },
      data: [],
    },
    {
      id: 'clicks',
      color: '#f6982e',
      legend: {
        value: campaignReport?.totalClicks || 0,
        color: '#fff',
      },
      data: [],
    },
    {
      id: 'Cost',
      color: '#804317',
      legend: {
        value: totalCost?.toFixed(2) || 0,
        color: '#fff',
      },
      data: [],
    },
    {
      id: 'ECPC',
      color: '#010a43',
      legend: {
        value: campaignReport?.totalClicks
          ? (totalCost / campaignReport?.totalClicks)?.toFixed(2)
          : 0,
        color: '#fff',
      },
      data: [],
    },
    {
      id: 'ECPV',
      color: '#463a3a',
      legend: {
        value: campaignReport?.totalVisits
          ? (totalCost / campaignReport?.totalVisits).toFixed(2)
          : 0,
        color: '#fff',
      },
      data: [],
    },
    {
      id: 'PPV',
      color: '#4d9c5d',
      legend: {
        value: report?.pageـviewsـper_session?.toFixed(2) || 0,
        color: '#fff',
      },
      data: [],
    },
    {
      id: 'Avg. SD',
      color: '#288cc2',
      legend: {
        value: report?.avg_session_duration
          ? moment
              .duration(report?.avg_session_duration)
              .asMinutes()
              .toFixed(2)
          : 0,
        unit: 'min',
        color: '#fff',
      },
      data: [],
    },
    {
      id: 'BR',
      color: '#ef4938',
      legend: {
        value: report?.bounce_rate?.toFixed(2) || 0,
        unit: '%',
        color: '#fff',
      },
      data: [],
    },
    {
      id: 'TC',
      color: '#2a7886',
      legend: {
        value: report?.total_conversions_count + assistedConversions || 0,
        color: '#fff',
      },
      data: [],
    },
  ];

  if (dailyReport && Object.keys(dailyReport)?.length) {
    for (const day in dailyReport) {
      lineChartData[0].data.push({
        x: day,
        y: dailyReport[day]?.totalVisits || 0,
      });

      lineChartData[1].data.push({
        x: day,
        y: dailyReport[day]?.totalClicks || 0,
      });

      lineChartData[5].data.push({
        x: day,
        y: dailyReport[day]?.pageـviewsـper_session?.toFixed(2) || 0,
      });

      lineChartData[6].data.push({
        x: day,
        y: dailyReport[day]?.avg_session_duration
          ? moment
              .duration(dailyReport[day]?.avg_session_duration)
              .asMinutes()
              .toFixed(2)
          : 0,
      });

      lineChartData[2].data.push({
        x: day,
        y: reformattedPaymanCost[day]?.toFixed(2) || 0,
      });

      lineChartData[3].data.push({
        x: day,
        y:
          dailyReport[day]?.totalCost && dailyReport[day]?.totalClicks
            ? (
                dailyReport[day]?.totalCost / dailyReport[day]?.totalClicks
              ).toFixed(2)
            : 0,
      });

      lineChartData[4].data.push({
        x: day,
        y:
          dailyReport[day]?.totalCost && dailyReport[day]?.totalVisits
            ? (
                dailyReport[day]?.totalCost / dailyReport[day]?.totalVisits
              ).toFixed(2)
            : 0,
      });
    }
  }

  return lineChartData;
};

const getStartEndDate = (packageObj, _startDate, _endDate) => {
  const packageStartDate =
    packageObj?.package_type === 'CUSTOM'
      ? packageObj?.custom_start_date
      : packageObj?.payment_date;

  const packageEndDate =
    packageObj?.package_type === 'CUSTOM'
      ? packageObj?.custom_end_date
      : packageObj?.next_payment_date;

  const todayDate = moment.utc().format('YYYY-MM-DD');

  const startDate = moment
    .utc(_startDate || packageStartDate)
    .format('YYYY-MM-DD');

  const endDate = moment
    .utc(_endDate || (packageEndDate > todayDate ? todayDate : packageEndDate))
    .format('YYYY-MM-DD');

  return [startDate, endDate];
};

const getPieData = devicesReport => {
  const colorsSchema = {
    desktop: '#656da3',
    mobile: '#f6982e',
    tablet: '#4d9c5d',
  };

  const pieData = devicesReport?.map(({ device, visits }) => {
    const deviceNameCapitalized = capitalizeFirstLetter(device);

    return {
      id: deviceNameCapitalized,
      label: deviceNameCapitalized,
      value: visits || 0,
      color: colorsSchema[device],
    };
  });

  return pieData;
};

const getMapData = countries => {
  const mapData = [];

  if (countries?.length) {
    for (const country in countries) {
      mapData.push({
        id: countries[country].country_code,
        value: countries[country].visits,
      });
    }
  }

  return mapData;
};

export default {
  name: 'Report',
  state: {
    loading: false,
    lineChartData: [],
    mapData: [],
    pieData: [],
    countries: [],
    cities: [],
    goalsConversionsTotal: 0,
    conversionsForAllGoals: {},
    goalNumbersData: {},
    startDate: '',
    endDate: '',
    error: false,
    errorMsg: '',
  },
  actions: {
    *getReport(campaignId, _startDate, _endDate) {
      try {
        yield {
          loading: true,
        };

        const campaign = yield CampaignsApi.getCampaignById(campaignId).catch(
          e => e,
        );

        handleApiError(campaign);

        const campaignObj = campaign.campaign;
        const { analytics_id: analyticsId } = campaignObj;
        const packageObj = campaignObj?.package;
        const [startDate, endDate] = getStartEndDate(
          packageObj,
          _startDate,
          _endDate,
        );

        const paymanCost = yield PaymentApi.getPaymanCost({
          campaignId,
          startDate,
          endDate,
        }).catch(e => e);

        handleApiError(paymanCost);

        yield {
          campaign: campaignObj,
          packageObj,
        };

        yield {
          startDate,
          endDate,
        };

        const visitsReport = yield CampaignsApi.getDailyClicksAndVisitsReport({
          devices: campaignObj?.devices?.join(','),
          countries: campaignObj?.countries?.join(','),
          campaignId,
          analyticsId,
          type: 'visits',
          startDate,
          endDate,
          utmSource: campaignObj?.delivery_utm_source,
        }).catch(e => e);

        const clicksReport = yield CampaignsApi.getDailyClicksAndVisitsReport({
          campaignId,
          analyticsId,
          type: 'clicks',
          startDate,
          endDate,
          utmSource: campaignObj?.delivery_utm_source,
        }).catch(e => e);

        const costReport = yield CampaignsApi.getCostReport({
          campaignId,
          analyticsId,
          startDate,
          endDate,
        }).catch(e => e);

        const report = yield CampaignsApi.getReport(
          campaignId,
          startDate,
          endDate,
          true,
          true,
          true,
        ).catch(e => e);

        handleApiError(report);

        const campaignReport = yield CampaignsApi.getCampaignResults(
          campaignId,
          startDate,
          endDate,
        ).catch(e => e);

        const devicesReport = report.devices && [...report.devices];
        const countries = report.results && [...report.results];
        const cities = report.cities && [...report.cities];

        if (report && report.data_source === 'google_analytics') {
          const assistedConversionsReport = yield CampaignsApi.getAssistedConversions(
            campaignObj.analytics_id,
            startDate,
            endDate,
          ).catch(e => e);

          const [
            goalsConversionsTotal,
            conversionsForAllGoals,
            goalNumbersData,
          ] = getConversionsReport(
            assistedConversionsReport,
            report,
            campaignObj.delivery_utm_source,
          );

          yield {
            goalsConversionsTotal,
            conversionsForAllGoals,
            goalNumbersData,
          };
        }

        yield {
          countries,
          cities,
          lineChartData: getLineChartData({
            report,
            campaignReport,
            assistedConversions: this.getState().goalsConversionsTotal
              ?.assistedConversions,
            paymanCost,
            dailyCost: costReport,
            dailyVisits: visitsReport,
            dailyClicks: clicksReport,
          }),
          mapData: getMapData(countries),
          pieData: getPieData(devicesReport),
        };

        yield {
          loading: false,
        };
      } catch (error) {
        const { message } = error;
        showMessage('error', message);
        yield {
          loading: false,
          error: true,
          errorMsg: message,
        };
      }
    },

    resetState() {
      return {
        loading: false,
        lineChartData: [],
        mapData: [],
        pieData: [],
        countries: [],
        cities: [],
        goalsConversionsTotal: 0,
        conversionsForAllGoals: {},
        goalNumbersData: {},
        error: false,
        errorMsg: '',
      };
    },
  },
};
