import { Form } from '@ant-design/compatible';
import { DatePicker, Input, Select } from 'antd';
import { formatDateSemantic } from 'utils/helpers';
import EditableField from 'components/EditableField';
import moment from 'moment';
import React, { useState } from 'react';
import Validation from 'utils/Validation';

import {
  RowContainerStyle,
  KeyContainerStyle,
  ValueContainerStyle,
} from 'pages/CampaignDetails/styles';
import { instascalerPackageTypes } from 'utils/constants';

const { Option } = Select;

const CycleInformationPanel = ({
  campaign,
  onEditSave,
  fieldsToEdit,
  editCampaignLoading,
  form,
  editMode,
}) => {
  const [editedValues, setEditedValues] = useState({
    state: '',
    total_visits: '',
    custom_start_date: '',
    custom_end_date: '',
  });

  const state = campaign.state;

  const campaignPackage = campaign.package || {};

  const instascalerCampaignStartDate = formatDateSemantic(campaign.started_at);
  const firstPaymentDate = formatDateSemantic(
    campaignPackage.first_payment_date,
  );
  const {
    custom_start_date,
    custom_end_date,
    total_visits,
    renewal_date,
  } = campaignPackage;

  const formattedRenewalDate = formatDateSemantic(renewal_date);

  const package_type = campaignPackage?.package_type;
  const isInstascalerCampaign = instascalerPackageTypes.includes(package_type);

  const canEditEndDate =
    state?.toLowerCase() !== 'suspended' && state?.toLowerCase() !== 'expired';
  const canEditStartDate = canEditEndDate && state?.toLowerCase() !== 'running';

  const isEditLoading = fieldKey =>
    editCampaignLoading && fieldsToEdit.includes(fieldKey);

  const disabledStartDate = dateStartValue => {
    const { custom_end_date: customEndDate } = campaignPackage;
    if (!dateStartValue || !customEndDate) {
      return false;
    }
    return dateStartValue.valueOf() > customEndDate.valueOf();
  };

  const disabledEndDate = dateEndValue => {
    const { custom_start_date } = campaignPackage;
    if (!dateEndValue || !custom_start_date) {
      return false;
    }
    return dateEndValue.valueOf() <= custom_start_date.valueOf();
  };

  const isSaveButtonDisabled = (value, originalValue) =>
    !value || value?.toString() === originalValue?.toString();

  const { getFieldDecorator, setFieldsValue } = form;

  return (
    <>
      <RowContainerStyle>
        <KeyContainerStyle>State</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            value={state}
            disableSaveButton={isSaveButtonDisabled(
              editedValues['state'],
              state,
            )}
            onSave={() => {
              onEditSave({ state: editedValues['state'] });
              setEditedValues({ ...editedValues, state: '' });
            }}
            onCancel={() => {
              setEditedValues({ ...editedValues, state });
              setFieldsValue({ ...editedValues, state });
            }}
            loadingEdit={isEditLoading('state')}
            editMode={editMode}
          >
            <Select
              labelInValue
              value={{ key: editedValues['state'] || state }}
              onChange={value =>
                setEditedValues({
                  ...editedValues,
                  state: value.key,
                })
              }
              data-test="choose-campaign-state"
            >
              <Option
                data-test="pending-state"
                value="PENDING"
                {...(campaign.state === 'RUNNING' ? { disabled: true } : {})}
              >
                Pending
              </Option>
              <Option
                data-test="pending-paid-state"
                value="PENDING_PAID"
                disabled
              >
                Pending paid
              </Option>
              <Option data-test="running-state" value="RUNNING">
                Running
              </Option>
              <Option data-test="suspended-state" value="SUSPENDED">
                Suspended
              </Option>
              <Option data-test="expired-state" value="EXPIRED" disabled>
                Expired
              </Option>
            </Select>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle hide={!isInstascalerCampaign}>
        <KeyContainerStyle>Start Date</KeyContainerStyle>
        <ValueContainerStyle>
          {instascalerCampaignStartDate}
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle hide={!isInstascalerCampaign}>
        <KeyContainerStyle>First Payment Date</KeyContainerStyle>
        <ValueContainerStyle>{firstPaymentDate}</ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle hide={!isInstascalerCampaign} lastRow>
        <KeyContainerStyle>Renewal Date</KeyContainerStyle>
        <ValueContainerStyle>{formattedRenewalDate}</ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle hide={isInstascalerCampaign}>
        <KeyContainerStyle>Total Visits</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            value={total_visits}
            disableSaveButton={isSaveButtonDisabled(
              editedValues['total_visits'],
              total_visits,
            )}
            onSave={() =>
              onEditSave({ total_visits: editedValues['total_visits'] })
            }
            onCancel={() => {
              setEditedValues({ ...editedValues, total_visits });
              setFieldsValue({ total_visits: total_visits });
            }}
            loadingEdit={isEditLoading('total_visits')}
            editMode={editMode}
          >
            <Form.Item>
              {getFieldDecorator('total_visits', {
                initialValue: total_visits,
                rules: [Validation.required],
              })(
                <Input
                  placeholder="Total Visits"
                  onChange={e =>
                    setEditedValues({
                      ...editedValues,
                      total_visits: e.target.value,
                    })
                  }
                  data-test={`total_visits-input`}
                />,
              )}
            </Form.Item>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle hide={isInstascalerCampaign}>
        <KeyContainerStyle>Start Date</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            disableEdit={!canEditStartDate}
            value={formatDateSemantic(custom_start_date)}
            disableSaveButton={isSaveButtonDisabled(
              formatDateSemantic(editedValues['custom_end_date']),
              formatDateSemantic(custom_end_date),
            )}
            onSave={() =>
              onEditSave({
                custom_start_date: editedValues['custom_start_date'],
              })
            }
            onCancel={() => {
              setEditedValues({ ...editedValues, custom_start_date });
              setFieldsValue({
                custom_start_date: moment.utc(custom_start_date),
              });
            }}
            loadingEdit={isEditLoading('custom_start_date')}
            renderShownValue={value => formatDateSemantic(value)}
            editMode={editMode}
          >
            {getFieldDecorator('custom_start_date', {
              initialValue: moment.utc(custom_start_date),
            })(
              <DatePicker
                disabledDate={e => disabledStartDate(e.format('YYYY-MM-DD'))}
                onChange={e =>
                  setEditedValues({
                    ...editedValues,
                    custom_start_date: e?.format('YYYY-MM-DD'),
                  })
                }
                format="YYYY-MM-DD"
                placeholder="Start date"
              />,
            )}
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle hide={isInstascalerCampaign} lastRow>
        <KeyContainerStyle>End Date</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            disableEdit={!canEditEndDate}
            value={formatDateSemantic(custom_end_date)}
            disableSaveButton={isSaveButtonDisabled(
              formatDateSemantic(editedValues['custom_end_date']),
              formatDateSemantic(custom_end_date),
            )}
            onSave={() =>
              onEditSave({
                custom_end_date: editedValues['custom_end_date'],
              })
            }
            onCancel={() => {
              setEditedValues({ ...editedValues, custom_end_date });
              setFieldsValue({
                custom_end_date: moment.utc(custom_end_date),
              });
            }}
            loadingEdit={isEditLoading('custom_end_date')}
            renderShownValue={value => formatDateSemantic(value)}
            editMode={editMode}
          >
            {getFieldDecorator('custom_end_date', {
              initialValue: moment.utc(custom_end_date),
            })(
              <DatePicker
                disabledDate={e => disabledEndDate(e.format('YYYY-MM-DD'))}
                onChange={e =>
                  setEditedValues({
                    ...editedValues,
                    custom_end_date: e?.format('YYYY-MM-DD'),
                  })
                }
                format="YYYY-MM-DD"
                placeholder="Start date"
              />,
            )}
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>
    </>
  );
};

export default Form.create()(CycleInformationPanel);
