import React from 'react';
import { formatDate } from 'utils/helpers';
import { CheckOutlined, FilePptOutlined } from '@ant-design/icons';
import theme from 'styles/themes';
import { getTrafficSourceName } from 'pages/CampaignDetails/Publishing/WfRunsTable/tableHelpers';

const { blue, green, red } = theme.colors;

export const eventsMapper = ({ historyLogs, workflowRuns, filter }) => {
  let logs = [...historyLogs, ...workflowRuns];
  logs = logs
    // sort by date
    .sort((log1, log2) => {
      const dateA = new Date(log1.inserted_on || log1.started_at);
      const dateB = new Date(log2.inserted_on || log2.started_at);
      return dateB - dateA;
    })
    // filter unnecessary events
    .filter(log => log?.event_name !== 'INSTA_CAMPAIGN_CREATE')
    // filter similar consecutive events
    .filter((log, index) => log?.event_name !== logs?.[index - 1]?.event_name);

  const readableLogs = logs.map(log => {
    const event = {};
    const eventName = log?.event_name || 'CAMPAIGN_PUBLISH';
    event.date = formatDate(log.inserted_on || log.started_at);
    event.id = log.id || log.wf_run_id;
    event.eventName = eventName;

    const cost = log?.metadata?.payload?.cost;
    const amountPaid = log?.metadata?.payload?.amount_paid;
    const paymentMethod = log?.metadata?.payload?.payment_method;

    switch (eventName) {
      case 'CAMPAIGN_CREATED': {
        event.title = 'Campaign Created';
        event.color = blue;
        break;
      }

      case 'CAMPAIGN_COST_UNDERSPENDING': {
        event.title = `Cost Underspending by ${cost} USD`;
        event.color = red;
        break;
      }

      case 'CAMPAIGN_COST_OVERSPENDING': {
        event.title = `Cost Overspending by ${cost} USD`;
        event.color = red;
        break;
      }

      case 'CAMPAIGN_COST_COLLECTED': {
        event.title = `Cost Collected ${cost} USD`;
        event.color = green;
        break;
      }

      case 'CAMPAIGN_RUNNING': {
        event.title = 'Campaign Running';
        event.color = blue;
        break;
      }

      case 'CAMPAIGN_RENEWED': {
        event.title = `Campaign Renewed, an amount of ${amountPaid} USD was paid through ${paymentMethod}`;
        event.color = green;
        break;
      }

      case 'CAMPAIGN_CANCELLED': {
        event.title = 'Campaign Cancelled';
        event.color = red;
        break;
      }

      case 'CAMPAIGN_PUBLISH': {
        event.title = `Campaign published on ${getTrafficSourceName(
          log?.workflow_name,
        )}, Campaign ID: ${log?.ts_campaign_id}`;
        event.icon = <FilePptOutlined style={{ color: green }} />;
        break;
      }

      case 'SUCCESS_PAYMENT': {
        event.title = `Payment Successful, an amount of ${amountPaid} USD was paid through ${paymentMethod}`;
        event.icon = <CheckOutlined style={{ color: green }} />;
        break;
      }

      default: {
        event.title = eventName?.replace(/_/g, ' ')?.toLowerCase();
        break;
      }
    }

    return event;
  });

  // filter according to the passed filter
  return readableLogs.filter(
    log => filter === 'ALL' || log.eventName === filter,
  );
};

export const eventTypes = [
  'ALL',
  'CAMPAIGN_CREATED',
  'CAMPAIGN_CANCELLED',
  'CAMPAIGN_RUNNING',
  'CAMPAIGN_RENEWED',
  'CAMPAIGN_PUBLISH',
  'SUCCESS_PAYMENT',
  'CAMPAIGN_COST_UNDERSPENDING',
  'CAMPAIGN_COST_OVERSPENDING',
  'CAMPAIGN_COST_COLLECTED',
];
