import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Select } from 'antd';
import { renderKeywords } from 'utils/helpers';

const KEYWORDS_PADDING = 20;

function Keywords({ getFieldDecorator, campaignKeywords, keywordsLoading }) {
  const [keywordsLength, setKeywords] = useState(KEYWORDS_PADDING);
  const campaignKeywordsLength = campaignKeywords?.split(';').length;

  return (
    <Form.Item style={{ marginBottom: 0 }}>
      {getFieldDecorator('keywords', {
        initialValue: renderKeywords(campaignKeywords),
      })(
        <Select
          data-test="keywordsSelector"
          id="select"
          mode="tags"
          size="default"
          allowClear
          loading={keywordsLoading}
          placeholder="Add a keyword +"
          dropdownClassName="selectKeywordsDropdown"
          maxTagCount={keywordsLength}
          maxTagPlaceholder={() => {
            return (
              <div
                onClick={() => {
                  setKeywords(keywordsLength + KEYWORDS_PADDING);
                }}
              >
                + {campaignKeywordsLength - keywordsLength} more...
              </div>
            );
          }}
        />,
      )}
    </Form.Item>
  );
}

export default Keywords;
