import {
  CampaignsApi,
  GAApi,
  IntegrationsApi,
  PackagesApi,
  PaymentApi,
  UsersApi,
  NotificationsApi,
} from 'utils/apiCalls';
import {
  handleApiError,
  showMessage,
  getPackageStartDate,
  getPackageEndDate,
} from 'utils/helpers';

export default {
  name: 'campaignDetails',
  state: {
    campaign: { package: {}, kampania: {} },
    campaignLinks: {},
    fetchCampaignError: null,
    fetchCampaignLoading: true,

    editLoading: false,
    fieldsToEdit: [],

    campaignCreatives: {},
    fetchCampaignCreativesLoading: false,

    campaignRules: [],
    fetchCampaignRulesLoading: true,

    campaignWorkflowRuns: [],
    fetchCampaignWorkflowRunsLoading: false,

    tsCampaigns: [],
    fetchTsCampaignsLoading: false,

    campaignLocations: [],
    fetchCampaignLocationsLoading: false,

    gaViews: [],
    fetchCampaignGAViewsLoading: false,

    fetchKampaniaDataLoading: false,
    fetchCampaignIntegrationsLoading: false,
    patchCampaignGAViewLoading: false,

    opentagScripts: [],
    fetchCampaignScriptsLoading: false,

    gaGoals: [],
    fetchCampaignGoalsLoading: null,

    accountTransactions: [],

    editCampaignOpenTagLoading: false,

    cloneCampaignLoading: false,

    gaIntegrationToken: '',
    generateGaIntegrationTokenLoading: false,

    fbIntegrationToken: '',
    generateFbIntegrationTokenLoading: false,

    revokeGaLoading: false,
    revokeFbLoading: false,

    archiveCampaignLoading: false,
    archiveCampaignError: '',
    isCampaignArchived: false,
    isCampaignUpdated: false,

    extendCampaignLoading: false,
    sucessExtendCampaign: false,
    extendCampaignError: '',

    editWeightLoading: false,
    doneEditingWeight: false,
  },
  actions: {
    *getCampaign(campaignId) {
      yield { fetchCampaignLoading: true };
      try {
        const data = yield CampaignsApi.getCampaignById(campaignId).catch(
          e => e,
        );
        handleApiError(data);

        const usersEmails = yield UsersApi.getCampaignUserEmails(
          data?.campaign?.emails,
        ).catch(e => e);

        handleApiError(usersEmails);

        if (data) {
          data.campaign.usersEmails = usersEmails;
        }
        yield {
          fetchCampaignLoading: false,
          fetchCampaignError: null,
          campaign: data?.campaign,
          campaignLinks: data?.links,
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchCampaignLoading: false,
          fetchCampaignError: JSON.parse(message),
        };
      }
    },

    *extendCampaign(params) {
      yield { extendCampaignLoading: true };
      try {
        const data = yield CampaignsApi.extendCampaign(params).catch(e => e);
        handleApiError(data);
        yield {
          extendCampaignLoading: false,
          sucessExtendCampaign: true,
        };
      } catch (err) {
        const { message } = err;
        yield {
          sucessExtendCampaign: false,
          extendCampaignLoading: false,
          extendCampaignError: message,
        };
      }
    },

    *archiveCampaign(campaignId) {
      yield { archiveCampaignLoading: true };
      try {
        const data = yield CampaignsApi.archiveCampaignById(campaignId).catch(
          e => e,
        );
        handleApiError(data);

        yield {
          archiveCampaignLoading: false,
          archiveCampaignError: null,
          isCampaignArchived: true,
        };
      } catch (err) {
        const { message } = err;
        yield {
          archiveCampaignLoading: false,
          archiveCampaignError: JSON.parse(message),
        };
      }
    },

    *editCampaign(updatedField) {
      try {
        const updatedFieldKeysArray = Object.keys(updatedField);

        const campaign = this.getState().campaign;

        const isInstascalerCampaign =
          campaign.delivery_utm_source.toLocaleLowerCase() === 'instascaler';

        yield {
          editLoading: true,
          fieldsToEdit: updatedFieldKeysArray,
        };

        const returnData = yield CampaignsApi.editCampaign({
          campaignId: campaign._id,
          updatedField,
          isInstascalerCampaign,
        }).catch(e => e);

        handleApiError(returnData);

        const newKampaniaObj = updatedFieldKeysArray.includes(
          'semaphoreFinalUrl',
        )
          ? {
              ...campaign.kampania,
              semaphore_final_url: updatedField.semaphoreFinalUrl,
            }
          : { ...campaign.kampania };

        yield {
          editLoading: false,
          isCampaignUpdated: true,
          campaign: { ...campaign, ...returnData, kampania: newKampaniaObj },
        };

        if (updatedField.state) {
          showMessage(
            'success',
            `Campaign changed to ${updatedField.state} successfully`,
          );
        } else {
          showMessage('success', 'Edited successfully');
        }
      } catch (err) {
        const { message } = err;
        yield {
          editLoading: false,
        };
        const displayMessage = JSON.parse(message)?.errorMsg;
        showMessage(
          'error',
          `Error happened${displayMessage ? `: ${displayMessage}` : ''}`,
        );
      }
    },

    *editPackage(updatedField) {
      const updatedFieldKeysArray = Object.keys(updatedField);

      const campaign = this.getState().campaign;

      const isInstascalerCampaign =
        campaign.delivery_utm_source.toLocaleLowerCase() === 'instascaler';

      yield {
        editLoading: true,
        fieldsToEdit: updatedFieldKeysArray,
      };
      try {
        const returnData = yield PackagesApi.editPackage({
          packageId: campaign.package._id,
          updatedField: { package: updatedField },
          isInstascalerCampaign,
        }).catch(e => e);
        handleApiError(returnData);

        yield {
          editLoading: false,
          campaign: { ...campaign, package: returnData },
        };
        showMessage('success', 'Edited successfully');
      } catch (err) {
        const { message } = err;
        yield {
          editLoading: false,
        };
        const displayMessage = JSON.parse(message)?.errorMsg;
        const errorCode = JSON.parse(message)?.errorCode;
        if (errorCode === 409) {
          yield {
            campaign: {
              ...campaign,
              package: { ...campaign.package, auto_renew: false },
            },
          };
        }
        showMessage(
          'error',
          `Error happened${displayMessage ? `: ${displayMessage}` : ''}`,
        );
      }
    },

    *getAccountTransactions() {
      const campaign = this.getState().campaign;

      let accountTransactionsData = [];
      const accountId = campaign?.package?.account;
      if (accountId) {
        const account = yield UsersApi.getAccount(accountId).catch(e => e);
        handleApiError(campaign);
        if (account.payman_account_id || account.payman_account_id === 0) {
          accountTransactionsData = yield PaymentApi.getAccountTransactions(
            accountId,
          ).catch(e => e);
          handleApiError(accountTransactionsData);

          yield {
            accountTransactions:
              accountTransactionsData.objects || accountTransactionsData,
          };
        }
      }
    },

    *getCampaignCreatives(campaignId) {
      yield { fetchCampaignCreativesLoading: true };
      try {
        const data = yield CampaignsApi.getCampaignCreatives(campaignId).catch(
          e => e,
        );
        handleApiError(data);

        yield {
          fetchCampaignCreativesLoading: false,
          campaignCreatives: data,
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchCampaignCreativesLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching campaign creatives${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *getKampaniaData(campaignId) {
      yield { fetchKampaniaDataLoading: true };
      try {
        const data = yield CampaignsApi.getKampaniaData(campaignId).catch(
          e => e,
        );
        handleApiError(data);
        const campaign = this.getState().campaign;

        yield {
          fetchKampaniaDataLoading: false,
          campaign: { ...campaign, kampania: data },
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchKampaniaDataLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching kampania data${message ? `: ${message}` : ''}`,
        );
      }
    },

    *getCampaignIntegrations(campaignId) {
      yield { fetchCampaignIntegrationsLoading: true };
      try {
        const data = yield CampaignsApi.getIntegrations(campaignId).catch(
          e => e,
        );
        handleApiError(data);
        const campaign = this.getState().campaign;

        yield {
          fetchCampaignIntegrationsLoading: false,
          campaign: { ...campaign, ...data },
        };
      } catch (err) {
        const { message } = err;

        yield {
          fetchCampaignIntegrationsLoading: false,
        };
        let displayedError = '';
        try {
          const messageObj = JSON.parse(message);
          displayedError = messageObj.errorMsg;
        } catch (error) {
          displayedError = message;
        }
        showMessage(
          'error',
          `Error while fetching campaign integrations data${
            displayedError ? `: ${displayedError}` : ''
          }`,
        );
      }
    },

    *getCampaignRules(campaignId) {
      yield { fetchCampaignRulesLoading: true };
      try {
        const data = yield CampaignsApi.getCampaignRules(campaignId).catch(
          e => e,
        );
        handleApiError(data);

        yield {
          fetchCampaignRulesLoading: false,
          campaignRules: data.objects,
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchCampaignRulesLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching campaign rules${message ? `: ${message}` : ''}`,
        );
      }
    },

    *getCampaignWorkflowRuns(campaignId) {
      yield { fetchCampaignWorkflowRunsLoading: true };
      try {
        const data = yield CampaignsApi.getCampaignWorkflowRuns(
          campaignId,
        ).catch(e => e);
        handleApiError(data);

        yield {
          fetchCampaignWorkflowRunsLoading: false,
          campaignWorkflowRuns: data,
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchCampaignWorkflowRunsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching campaign workflow runs${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *getTsCampaigns(kampaniaId) {
      try {
        if (kampaniaId) {
          yield { fetchTsCampaignsLoading: true };

          const data = yield CampaignsApi.getTsCampaigns(kampaniaId).catch(
            e => e,
          );

          handleApiError(data);

          yield {
            fetchTsCampaignsLoading: false,
            tsCampaigns: data,
          };
        }
      } catch (err) {
        const { message } = err;
        yield {
          fetchTsCampaignsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching traffic source campaigns${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *getCampaignGaGoals(campaignId) {
      yield { fetchCampaignGoalsLoading: true };
      try {
        const data = yield GAApi.getCampaignGoals(campaignId).catch(e => e);

        handleApiError(data);
        yield {
          fetchCampaignGoalsLoading: false,
          gaGoals: data.conversions || [],
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchCampaignGoalsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching campaign goals${message ? `: ${message}` : ''}`,
        );
      }
    },

    *getCampaignScripts() {
      yield { fetchCampaignScriptsLoading: true };
      const campaign = this.getState().campaign;

      const encryptedId = campaign.encrypted_id;

      if (encryptedId)
        try {
          const data = yield CampaignsApi.getCampaignOpentagScripts(
            encryptedId,
          ).catch(e => e);
          handleApiError(data);

          yield {
            opentagScripts: data,
            fetchCampaignScriptsLoading: false,
          };
        } catch (err) {
          const { message } = err;
          yield {
            fetchCampaignScriptsLoading: false,
          };
          showMessage(
            'error',
            `Error while fetching campaign scripts${
              message ? `: ${message}` : ''
            }`,
          );
        }
    },

    *editCampaignOpentagScripts(campaignId, newOpentagScripts) {
      yield {
        editCampaignOpenTagLoading: true,
      };

      const campaign = this.getState().campaign;

      const isInstascalerCampaign =
        campaign.delivery_utm_source.toLocaleLowerCase() === 'instascaler';

      try {
        const data = yield CampaignsApi.editCampaignOpentagScripts(
          campaignId,
          newOpentagScripts,
          isInstascalerCampaign,
        ).catch(e => e);
        handleApiError(data);

        const campaign = this.getState().campaign;

        yield {
          editCampaignOpenTagLoading: false,
          opentagScripts: data.scripts,
          campaign: {
            ...campaign,
            optimize_with:
              newOpentagScripts?.analyticsTrackingService?.optimize_with,
          },
        };
        showMessage('success', 'Edited successfully');
      } catch (err) {
        const { message } = err;
        yield {
          editCampaignOpenTagLoading: false,
        };
        showMessage(
          'error',
          `Error while editing${message ? `: ${message}` : ''}`,
        );
      }
    },

    *getCampaignGAViews(campaignId) {
      yield { fetchCampaignGAViewsLoading: true };
      try {
        const data = yield GAApi.getCampaignGAViews(campaignId).catch(e => e);
        handleApiError(data);

        const formattedGAViews = [];

        data.web_properties.forEach(g => {
          g.views.forEach(v =>
            formattedGAViews.push({
              ...v,
              web_property_id: g.id,
              web_property_name: g.name,
            }),
          );
        });

        yield {
          fetchCampaignGAViewsLoading: false,
          gaViews: formattedGAViews,
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchCampaignGAViewsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching GA views${message ? `: ${message}` : ''}`,
        );
      }
    },

    *patchCampaignGAView({ campaignId, viewId, webPropertyId }) {
      yield {
        patchCampaignGAViewLoading: true,
      };

      try {
        const data = yield GAApi.patchGAView({
          campaignId,
          viewId,
          webPropertyId,
        }).catch(e => e);
        handleApiError(data);

        const campaign = this.getState().campaign;

        yield {
          patchCampaignGAViewLoading: false,
          campaign: {
            ...campaign,
            google_analytics: {
              ...campaign.google_analytics,
              profile_id: viewId,
            },
          },
        };
      } catch (err) {
        const { message } = err;
        yield {
          patchCampaignGAViewLoading: false,
        };
        showMessage(
          'error',
          `Error while patching views${message ? `: ${message}` : ''}`,
        );
      }
    },

    *cloneCampaign(wfrunId, trafficSource) {
      yield { cloneCampaignLoading: true };
      try {
        const clonedCampaign = yield CampaignsApi.cloneCampaign(
          wfrunId,
          trafficSource,
        ).catch(e => e);
        handleApiError(clonedCampaign);

        yield { cloneCampaignLoading: false };

        showMessage('success', 'Campaign was successfully cloned');
      } catch (err) {
        yield {
          cloneCampaignLoading: false,
        };
        showMessage('error', 'Error while cloning campaign');
      }
    },

    *generateIntegrationToken(campaignId, platform) {
      let loadingState = '';
      let valueState = '';

      switch (platform) {
        case 'google':
          loadingState = 'generateGaIntegrationTokenLoading';
          valueState = 'gaIntegrationToken';
          break;
        case 'facebook':
          loadingState = 'generateFbIntegrationTokenLoading';
          valueState = 'fbIntegrationToken';
          break;
        default:
          break;
      }

      yield {
        [loadingState]: true,
      };

      const campaign = this.getState().campaign;

      const isInstascalerCampaign =
        campaign.delivery_utm_source.toLocaleLowerCase() === 'instascaler';

      try {
        const integrationLink = yield IntegrationsApi.generateIntegrationToken(
          campaignId,
          isInstascalerCampaign,
        ).catch(e => e);
        handleApiError(integrationLink);

        yield {
          [valueState]: integrationLink.token,
          [loadingState]: false,
        };
      } catch (err) {
        const { message } = err;
        yield {
          [loadingState]: false,
        };
        showMessage(
          'error',
          `Error while generating token${message ? `: ${message}` : ''}`,
        );
      }
    },

    *revokeGaIntegration(campaignId) {
      yield {
        revokeGaLoading: true,
      };
      const campaign = this.getState().campaign;
      const id = campaignId || campaign._id;

      try {
        const data = yield IntegrationsApi.revokeGa(id).catch(e => e);
        handleApiError(data);

        yield {
          campaign: { ...campaign, is_ga_integrated: false },
          revokeGaLoading: false,
        };
      } catch (err) {
        const { message } = err;
        yield {
          revokeGaLoading: false,
        };
        showMessage(
          'error',
          `Error while revoking${message ? `: ${message}` : ''}`,
        );
      }
    },

    *revokeFbIntegration(campaignId) {
      yield {
        revokeFbLoading: true,
      };
      const campaign = this.getState().campaign;
      const id = campaignId || campaign._id;

      try {
        const data = yield IntegrationsApi.revokeFb(id).catch(e => e);
        handleApiError(data);
        yield {
          campaign: { ...campaign, is_fb_integrated: false },
        };

        yield {
          revokeFbLoading: false,
        };
      } catch (err) {
        const { message } = err;
        yield {
          revokeFbLoading: false,
        };
        showMessage(
          'error',
          `Error while revoking${message ? `: ${message}` : ''}`,
        );
      }
    },

    *editTSCampaignWeight(weight, wf_run_id) {
      try {
        yield {
          editWeightLoading: true,
        };

        const campaign = this.getState('campaign');
        const packageObj = campaign?.package;

        if (packageObj) {
          const start_date = getPackageStartDate(packageObj);
          const end_date = getPackageEndDate(packageObj);
          const maxAllowedBudget = packageObj?.balance * packageObj?.abm;

          const reqBody = {
            wf_run_id,
            end_date,
            start_date,
            weight: weight / 100,
            budget: maxAllowedBudget,
          };

          const editWeight = yield CampaignsApi.editTSCampaignWeight(reqBody);

          handleApiError(editWeight);

          yield {
            doneEditingWeight: true,
            editWeightLoading: false,
          };
        }
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        showMessage(
          'error',
          `Error while Editing TS campaign weight${
            errorMsg ? `: ${errorMsg}` : ''
          }`,
        );
        yield {
          editWeightLoading: false,
        };
      }
    },

    *sendNotification(campaignId, body, closeDrawer) {
      try {
        yield {
          sendNotificationLoading: true,
        };

        const sendNotifications = yield NotificationsApi.sendNotification(
          campaignId,
          body,
        ).catch(e => e);

        handleApiError(sendNotifications);

        showMessage('success', `Your notification has been sent successfully!`);

        yield {
          sendNotificationLoading: false,
        };

        closeDrawer();
      } catch (err) {
        const { message } = err;
        yield {
          sendNotificationLoading: false,
        };
        showMessage(
          'error',
          `Error while sending notification${message ? `: ${message}` : ''}`,
        );
      }
    },

    resetState() {
      return {
        campaignLinks: {},
        fetchCampaignError: null,
        fetchCampaignLoading: false,

        editLoading: false,
        fieldsToEdit: [],

        campaignRules: [],
        fetchCampaignRulesLoading: false,

        campaignWorkflowRuns: [],
        fetchCampaignWorkflowRunsLoading: false,

        campaignLocations: [],
        fetchCampaignLocationsLoading: false,

        gaViews: [],
        fetchCampaignGAViewsLoading: false,

        fetchKampaniaDataLoading: false,
        fetchCampaignIntegrationsLoading: false,
        patchCampaignGAViewLoading: false,

        opentagScripts: [],
        fetchCampaignScriptsLoading: false,

        gaGoals: [],
        fetchCampaignGoalsLoading: null,

        accountTransactions: [],

        editCampaignOpenTagLoading: false,

        cloneCampaignLoading: false,

        gaIntegrationToken: '',
        generateGaIntegrationTokenLoading: false,

        fbIntegrationToken: '',
        generateFbIntegrationTokenLoading: false,

        revokeGaLoading: false,
        revokeFbLoading: false,

        archiveCampaignLoading: false,
        archiveCampaignError: '',
        isCampaignArchived: false,
        isCampaignUpdated: false,

        extendCampaignLoading: false,
        sucessExtendCampaign: false,
        extendCampaignError: '',
      };
    },

    resetCampaign() {
      return {
        campaign: { package: {}, kampania: {} },
      };
    },

    resetCampaignCreatives() {
      return {
        campaignCreatives: {},
        fetchCampaignCreativesLoading: false,
      };
    },

    resetEditWeight() {
      return {
        editWeightLoading: false,
        doneEditingWeight: false,
      };
    },
  },
  globalState: {
    fetchAllCampaignsLoading: 'campaignsList.fetchCampaignsLoading',
  },
};
