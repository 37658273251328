import { UsersApi, UploadApi } from 'utils/apiCalls';
import { handleApiError } from 'utils/helpers';
import { message } from 'antd';

export default {
  name: 'Partners',
  state: {
    getAllPartnersLoading: false,
    partnersList: [],
    partnersCount: 0,
    createPartnerLoading: false,
    partnerCreated: false,
    error: '',
  },
  actions: {
    *getPartners(urlParams) {
      yield { getAllPartnersLoading: true };

      const getAllPartners = yield UsersApi.getPartners(urlParams).catch(
        e => e,
      );
      handleApiError(getAllPartners);

      yield {
        partnersCount: getAllPartners.headers['x-count'],
        partnersList: getAllPartners.data,
        getAllPartnersLoading: false,
      };
    },

    *createPartner(partnerData) {
      try {
        yield { createPartnerLoading: true };
        const partnerResponse = yield UsersApi.addPartner(partnerData).catch(
          e => e,
        );
        handleApiError(partnerResponse);

        yield {
          createPartnerLoading: false,
          partnerCreated: true,
        };
      } catch (err) {
        const { errorMsg } = JSON.parse(err.message);
        message.error(errorMsg);
        yield {
          createPartnerLoading: false,
          error: errorMsg,
        };
      }
    },

    resetPartnerCreated() {
      return {
        partnerCreated: false,
      };
    },

    *generatePresignedUrl(campaignId) {
      const result = yield UploadApi.generatePresignedUrl(campaignId, {
        type: 'logoUploads',
      }).catch(e => e);
      handleApiError(result);

      const generatedURL = result.url;
      yield {
        generatedURL,
      };
    },

    uploadFile(action, file, headers, onSuccess, onError) {
      fetch(action, {
        method: 'PUT',
        body: file,
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            onSuccess(res, file);
          }
        })
        .catch(error => {
          onError(error);
        });
    },
  },
};
