import { message } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import theme from 'styles/themes';

import { TIME_FORMAT, DATE_FORMAT } from '../constants';

export const handleApiError = res => {
  if (res instanceof Error) {
    const { errorMsg, errorCode } = JSON.parse(res.message);

    const error = {
      errorCode,
      errorMsg,
    };

    throw new Error(JSON.stringify(error));
  }
};

export const formatList = list => {
  try {
    const fl = new Intl.ListFormat('en');
    return fl.format(list);
  } catch {
    return list?.join(', ');
  }
};

export const renderKeywords = stringSemiColonSeperated =>
  stringSemiColonSeperated ? stringSemiColonSeperated.split(';') : [];

export const showMessage = (type, msg) => message[type](msg, 10);

export const formatDateSemantic = date => {
  if (date) {
    return moment.utc(date).format('MMM D YYYY');
  }
  return null;
};

export const formatDate = (date, reversed) => {
  const newDate = new Date(date);
  let month = `${newDate.getMonth() + 1}`;
  let day = `${newDate.getDate()}`;
  const year = newDate.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  if (reversed === 'reversed') {
    return [day, month, year].join('-');
  }

  return [year, month, day].join('-');
};

export const isURL = str => {
  return /^(?:\w+:)?\/\/([^\s]+\.\S{2}|localhost[?\d]*)\S*$/.test(str);
};

export const getPackageStartDate = (_package, customDateFormat) => {
  return moment
    .utc(
      _package?.package_type === 'CUSTOM'
        ? _package?.custom_start_date
        : _package?.payment_date,
    )
    .format(customDateFormat || DATE_FORMAT);
};

export const getPackageEndDate = (_package, customDateFormat) => {
  return moment
    .utc(
      _package?.package_type === 'CUSTOM'
        ? _package?.custom_end_date
        : _package?.next_payment_date,
    )
    .format(customDateFormat || DATE_FORMAT);
};

export const predefinedDateRanges = {
  Today: [moment.utc(), moment.utc()],
  Yesterday: [moment.utc().subtract(1, 'day'), moment.utc().subtract(1, 'day')],
  'Last 7 Days': [moment.utc().subtract(6, 'days'), moment.utc()],
  'Last 30 Days': [moment.utc().subtract(29, 'days'), moment.utc()],
};

export const addGoalsToTable = (goals, goalsNames, columns) => {
  let allCols = columns.columns;
  if (goals && goalsNames && Object.keys(goals).length) {
    const children = Object.keys(goals).map((key, i) => ({
      title: i === 0 ? 'Goal Completions All' : goalsNames[i - 1].name,
      dataIndex: key,
      key,
      width: 200,
      sorter: (a, b) => a[key] - b[key],
    }));
    const goalsTable = [
      {
        title: 'Goals',
        children,
      },
    ];
    allCols = columns.columns.concat(goalsTable);
  }

  return allCols;
};

export const createGoalsObj = (arr, reports) => {
  const goalsObj = {};
  reports?.goals?.length &&
    reports.reports[1].columnHeader.metricHeader.metricHeaderEntries.forEach(
      (goal, i) => {
        goalsObj[goal.name] = arr.length && i < arr.length ? arr[i] : '0';
      },
    );

  return goalsObj;
};

export const reformatGAData = (reports, columns) => {
  const tableHeadMetrics = reports.reports[0].columnHeader.metricHeader.metricHeaderEntries.map(
    entry => entry.name,
  );

  const goalsObj = createGoalsObj([], reports);
  const { rows = [] } = reports.reports[0].data;
  const goalsData = Array(...Array(rows.length)).map(
    Object.prototype.valueOf,
    goalsObj,
  );
  if (
    reports.goals &&
    reports.goals.length &&
    reports.reports[1].data &&
    reports.reports[1].data.rows
  ) {
    reports.reports[1].data.rows.forEach(reportWithGoals => {
      goalsData.forEach((initialGoalVal, i) => {
        if (
          reports.reports[0].data.rows[i].dimensions[0] ===
            reportWithGoals.dimensions[0] &&
          reports.reports[0].data.rows[i].dimensions[1] ===
            reportWithGoals.dimensions[1]
        ) {
          goalsData[i] = createGoalsObj(
            reportWithGoals.metrics[0].values,
            reports,
          );
        }
      });
    });
  }

  const tableCols = addGoalsToTable(goalsData[0], reports.goals, columns);
  let tableData = [];

  if (reports.reports[0].data.rows) {
    tableData = reports.reports[0].data.rows.map((rec, i) => {
      const newUTM = reports.reports[0].columnHeader.dimensions.reduce(
        (p, n, index) => ({ ...p, [n]: rec.dimensions[index] }),
        {},
      );
      const newData = tableHeadMetrics.reduce(
        (p, n, index) => ({ ...p, [n]: rec.metrics[0].values[index] }),
        {},
      );
      return { ...newUTM, ...newData, ...goalsData[i] };
    });
    return {
      tableData,
      tableCols,
    };
  }
  return {
    tableData: [],
    tableCols: [],
  };
};

export const convertSecondsToHHMMSS = seconds => {
  return moment.utc(seconds * 1000).format(TIME_FORMAT);
};

export const convertArrayOfObjectsToCSV = args => {
  let result = '';
  let ctr = 0;
  const data = args || null;
  const columnDelimiter = ',';
  const lineDelimiter = '\n';

  if (data == null || !data.length) {
    return null;
  }

  const keys = Object.keys(data[0]);

  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(item => {
    ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;
      if (key === 'ga:avgSessionDuration')
        result += convertSecondsToHHMMSS(item[key]);
      else result += item[key];
      ctr += 1;
    });
    result += lineDelimiter;
  });

  return result;
};

export const downloadCSV = (args, filename) => {
  let csv = convertArrayOfObjectsToCSV(args);
  if (csv == null) return;
  const blobdata = new Blob([csv], { type: 'text/csv' });

  const filename2 = `${filename}.csv` || 'campaign.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8, ${csv}`;
  }

  const link = document.createElement('a');
  link.setAttribute('href', window.URL.createObjectURL(blobdata));
  link.setAttribute('download', filename2);
  link.click();
};

export const downloadJSONFile = (data, filename) => {
  const filename2 = `${filename}.json` || 'campaign.json';

  const dataStr = `data:text/json;charset=utf-8, ${encodeURIComponent(
    JSON.stringify(data),
  )}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', filename2);
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const useOutsideClick = (ref, onClick) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClick();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

export const base64ImageToBlob = ({ name, extension, base64 }) => {
  // extract content type and base64 payload from original string
  const type = extension;
  const b64 = base64;

  // decode base64
  const imageContent = atob(b64);

  // create an ArrayBuffer and a view (as unsigned 8-bit)
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  // fill the view, using the decoded base64
  for (let n = 0; n < imageContent.length; n++) {
    // eslint-disable-line
    view[n] = imageContent.charCodeAt(n);
  }

  // convert ArrayBuffer to Blob
  const blob = new File([buffer], name, { type });

  return blob;
};

export const notificationsEvent = [
  'CAMPAIGN_PENDING_PAID_EVENT',
  'CAMPAIGN_RENEWED',
  'SUCCESS_PAYMENT',
];

export const getNotificationBody = notification => {
  switch (notification?.event_name) {
    case 'CAMPAIGN_PENDING_PAID_EVENT':
      return {
        msg: `Campaign ${notification?.instascaler_id} is Ready to be published!`,
        campaignId: notification?.instascaler_id,
        date: notification?.inserted_on,
        color: theme?.colors?.orange,
      };
    case 'CAMPAIGN_RENEWED':
      return {
        msg: `Campaign ${notification?.instascaler_id} was renewed with amount of ${notification?.metadata?.payload?.amount_paid}$!`,
        campaignId: notification?.instascaler_id,
        date: notification?.inserted_on,
        color: theme?.colors?.green,
      };
    case 'SUCCESS_PAYMENT':
      return {
        msg: `Campaign ${notification?.instascaler_id} paid successfully with amount of ${notification?.metadata?.payload?.amount_paid}$!`,
        campaignId: notification?.instascaler_id,
        date: notification?.inserted_on,
        color: theme?.colors?.blue,
      };
    default:
      return {};
  }
};

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);
